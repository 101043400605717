import RemittanceIcon from 'assets/ver2/icons/icon_remittance.svg';
import ExchangeIcon from 'assets/ver2/icons/icon_exchange.svg';
import TodaysRateIcon from 'assets/ver2/icons/todays-rate.svg';
import TodaysRateIconActive from 'assets/ver2/icons/todays-rate-active.svg';
import TransactionnsIcon from 'assets/ver2/icons/transactions.svg';
import TransactionnsIconActive from 'assets/ver2/icons/transactions-active.svg';
import ReceiversIconIcon from 'assets/ver2/icons/receivers.svg';
import ReceiversIconActive from 'assets/ver2/icons/receivers-active.svg';
import ReceiversIndividualIcon from 'assets/ver2/icons/receiver_personal.svg';
import ReceiversIndividualIconActive from 'assets/ver2/icons/receiver_personal_active.svg';
import ReceiversBusinessIcon from 'assets/ver2/icons/receiver_business.svg';
import ReceiversBusinessIconActive from 'assets/ver2/icons/receiver_business_active.svg';
import ArrowRightBlackIcon from 'assets/ver2/icons/arrow-right-black.svg';
import ArrowRightWhiteIcon from 'assets/ver2/icons/arrow-right-white.svg';

import {
  DASHBOARD_PREFIX,
  TRANSACTIONS_PREFIX,
  RECEIVERS_PREFIX,
  FX_DASHBOARD_PREFIX,
  FX_TRANSACTIONS_PREFIX,
} from 'constants/router';

export const REMITTANCE_TITLE = {
  text: 'transactions_nav_label',
  icon: RemittanceIcon,
};

export const REMITTANCE_NAV = [
  {
    text: 'label_side_remittance_today_rate',
    icon: TodaysRateIcon,
    iconActive: TodaysRateIconActive,
    href: `${DASHBOARD_PREFIX}`,
  },
  {
    text: 'label_side_remittance_transactions',
    icon: TransactionnsIcon,
    iconActive: TransactionnsIconActive,
    href: `${TRANSACTIONS_PREFIX}`,
  },
];

export const REMITTANCE_MOBILE_NAV = [
  {
    text: 'label_side_remittance_today_rate_mobile',
    href: `${DASHBOARD_PREFIX}`,
  },
  {
    text: 'label_side_remittance_transactions',
    href: `${TRANSACTIONS_PREFIX}`,
  },
  {
    text: 'label_side_remittance_receivers',
    href: `${RECEIVERS_PREFIX}`,
  },
];

export const RECEIVERS_TITLE = {
  text: 'label_side_remittance_receivers',
  icon: ReceiversIconIcon,
  iconActive: ReceiversIconActive,
  href: `${RECEIVERS_PREFIX}`,
};

export const RECEIVERS_SUB_MENU = [
  {
    text: 'label_individual_receiver',
    icon: ReceiversIndividualIcon,
    iconActive: ReceiversIndividualIconActive,
    arrow: ArrowRightBlackIcon,
    arrowActive: ArrowRightWhiteIcon,
    href: '',
  },
  {
    text: 'label_business_receiver',
    icon: ReceiversBusinessIcon,
    iconActive: ReceiversBusinessIconActive,
    arrow: ArrowRightBlackIcon,
    arrowActive: ArrowRightWhiteIcon,
    href: '',
  },
];

export const EXCHANGE_TITLE = {
  text: 'fx_nav_label',
  icon: ExchangeIcon,
};

export const EXCHANGE_NAV = [
  {
    text: 'label_side_exchange_rate',
    icon: TodaysRateIcon,
    iconActive: TodaysRateIconActive,
    href: `${FX_DASHBOARD_PREFIX}`,
  },
  {
    text: 'label_side_exchange_transactions',
    icon: TransactionnsIcon,
    iconActive: TransactionnsIconActive,
    href: `${FX_TRANSACTIONS_PREFIX}`,
  },
];
