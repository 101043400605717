/* eslint-disable no-unused-vars */
import ButtonCommon from 'components/common/ButtonCommon';
import { LogUtil } from 'components/common/LogUtil';
import {
  CALCULATOR_DESKTOP_WIDTH,
  DESKTOP_MIN_WIDTH,
  FX_TRANSACTION_ADD_PREFIX,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import {
  FX_CALCULATOR_FORM_AMOUNT_FIELDS,
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
} from 'constants/fx';
import { format } from 'helpers';
import useFxCalculator, {
  initialCalculatorForm,
} from 'hooks/fx/useFxCalculator';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useClickEventListener from 'hooks/useClickEventListener';
import useCountdownToAction from 'hooks/useCountdownToAction';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Fade, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import FormInputAmountValidation from '../components/FormInputAmountValidation';

const EXCHANGE_RATE_OUTDATE_COUNTDOWN_DEFAULT = 600; //600s = 10m

const CalculatorModal = ({ isOpen, onClose, orderEdit }) => {
  const { t } = useLang();
  const navigate = useNavigate();

  const [information, setInformation] = useState(initialCalculatorForm);
  const [validation, setValidation] = useState(initialCalculatorForm);
  const [currencies, setCurrencies] = useState(null);
  const [isHiddenCalculatorModal, setHiddenCalculatorModal] = useState(true);
  const [isChangeCurrency, setChangeCurrency] = useState(false);
  const [currentOrderEdit, setCurrentOrderEdit] = useState(null);
  const [isRounding, setRounding] = useState(false);

  const {
    getPayAmountLimitErrorMessage,
    setSellCurrencies,
    getCalculatorFormByCurrencyChange,
    refreshCalculatorByExchangeRateOutdate,
  } = useFxCalculator();
  const { addOrderToCurrencyOrders, currencyOrders } =
    useFxFormAddTransactionStore();
  const { isFxAddTransactionPage, isFxAddTransactionAsGuestPage } = usePage();

  const selectCurrencyDrawerRef = useRef();

  const { currentCurrencySelected, sellRate } = information;
  const { currencyCode } = currentCurrencySelected || {};

  const isEdit = !isEmpty(orderEdit);
  const isActionButtonEdit = !isEmpty(orderEdit) && isFxAddTransactionPage;

  /* BEGIN - check rate outdate */
  const handleExchangeRateOutdate = async (loopCallback) => {
    refreshCalculatorByExchangeRateOutdate({
      information,
      setInformation,
      currencies,
      callback: loopCallback,
    });
  };
  useCountdownToAction(
    0,
    !isFxAddTransactionPage &&
      !isFxAddTransactionAsGuestPage &&
      isOpen &&
      !isHiddenCalculatorModal,
    handleExchangeRateOutdate,
    [
      isFxAddTransactionPage,
      isFxAddTransactionAsGuestPage,
      isOpen,
      isHiddenCalculatorModal,
    ],
    EXCHANGE_RATE_OUTDATE_COUNTDOWN_DEFAULT,
    true
  );
  /* END - check rate outdate */

  useEffect(() => {
    setSellCurrencies(setCurrencies);
    setRounding(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* BEGIN - clean you pay validate */
  useEffect(() => {
    const payAmount = information[FX_CALCULATOR_FORM_PAY_NAME];
    if (!payAmount) return;

    const isCleanCheckPayAmountLimitMessage =
      !selectCurrencyDrawerRef?.current?.isShowNavbar &&
      isEmpty(currencyOrders) &&
      validation[FX_CALCULATOR_FORM_PAY_NAME];
    if (isCleanCheckPayAmountLimitMessage) {
      const newValidation = {
        ...validation,
        [FX_CALCULATOR_FORM_PAY_NAME]: '',
      };
      setValidation(newValidation);
    }
  }, [information]);
  /* BEGIN - clean you pay validate */

  useEffect(() => {
    const convertFromAmount = Number(
      information[FX_CALCULATOR_FORM_PAY_NAME] || 0
    );
    const toAmount = Number(information[FX_CALCULATOR_FORM_BUY_NAME] || 0);

    const payEle = document.getElementsByName(FX_CALCULATOR_FORM_PAY_NAME)?.[0];
    if (payEle) {
      payEle.value = convertFromAmount
        ? format.toAmountStr(convertFromAmount)
        : 0;
    }

    const buyEle = document.getElementsByName(FX_CALCULATOR_FORM_BUY_NAME)?.[0];
    if (buyEle) {
      buyEle.value = toAmount ? format.toAmountStr(toAmount) : 0;
    }
  }, [information]);

  // EDIT
  useEffect(() => {
    if (!isEdit) return;
    if (isEmpty(currencies)) return;
    setHiddenCalculatorModal(false);
    getCalculatorFormByCurrencyChange({
      currencies,
      information: orderEdit,
      setInformation,
    });
    setCurrentOrderEdit(orderEdit);
  }, [isEdit, currencies]);

  // ADD
  useEffect(() => {
    if (isEdit) return;
    if (!isEmpty(currentCurrencySelected) && !isEmpty(sellRate)) {
      setHiddenCalculatorModal(false);
    }
  }, [isEdit, currentCurrencySelected, sellRate]);

  const handleOnToggleNavbar = () => {
    selectCurrencyDrawerRef?.current?.handleOnToggleNavbar();
  };
  useEffect(() => {
    if (isEdit) return;
    handleOnToggleNavbar();
  }, [isEdit]);

  const handleOnClickCloseButton = () => {
    if (isEdit) return;
    if (isChangeCurrency) {
      setChangeCurrency(false);
      handleOnToggleNavbar();
    }
    onClose();
  };
  useClickEventListener({
    className: 'btn-close',
    callback: () => handleOnClickCloseButton(),
  });

  const handleAddToOrder = () => {
    if (isRounding) return;

    const payAmount = information[FX_CALCULATOR_FORM_PAY_NAME];
    if (!payAmount) return;

    const payAmountLimitErrorMessage = getPayAmountLimitErrorMessage(
      t,
      payAmount
    );
    const isCheckPayAmountLimit =
      isEmpty(currencyOrders) &&
      payAmountLimitErrorMessage &&
      !validation[FX_CALCULATOR_FORM_PAY_NAME];
    if (isCheckPayAmountLimit) {
      const newValidation = {
        ...validation,
        [FX_CALCULATOR_FORM_PAY_NAME]: payAmountLimitErrorMessage,
      };
      setValidation(newValidation);
      return;
    }

    if (validation[FX_CALCULATOR_FORM_PAY_NAME]) return;

    const order = information;
    LogUtil.log('handleAddToOrder', 'order', order);
    addOrderToCurrencyOrders(order, currentOrderEdit);

    onClose();

    if (!isFxAddTransactionPage) {
      navigate(FX_TRANSACTION_ADD_PREFIX);
    }
  };

  return (
    <>
      <style>
        {`
          .calculator-modal-classname {}
          .calculator-modal-backdrop-classname {
            opacity: ${isHiddenCalculatorModal ? 0 : 0.25}!important;
          }
        `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        className="calculator-modal-classname"
        backdropClassName="calculator-modal-backdrop-classname"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        $isHidden={isHiddenCalculatorModal}
      >
        <ModalHeader>
          <Rate
            dangerouslySetInnerHTML={{
              __html: `1 AUD =&nbsp;<strong>${format.toAmountStr2(
                sellRate?.rate || 0
              )}</strong>&nbsp;${currencyCode}`,
            }}
          />
        </ModalHeader>
        <ModalBody>
          {FX_CALCULATOR_FORM_AMOUNT_FIELDS.map((field) => (
            <FormInputAmountValidation
              ref={selectCurrencyDrawerRef}
              key={field.name}
              field={field}
              currencies={currencies}
              setCurrencies={setCurrencies}
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
              setChangeCurrency={setChangeCurrency}
              setRounding={setRounding}
            />
          ))}
          <FormControlFeedback
            hidden={!validation[FX_CALCULATOR_FORM_PAY_NAME]}
            type="invalid"
          >
            {t(validation[FX_CALCULATOR_FORM_PAY_NAME])}
          </FormControlFeedback>
        </ModalBody>
        <RoundNoteText
          dangerouslySetInnerHTML={{
            __html: t('fx_calculator_modal_input_amount_round_note_web'),
          }}
        />
        <ModalFooter>
          <Fade in appear>
            <NextWrap>
              <ButtonCommon
                value={t('button_cancel')}
                onClick={onClose}
                styles={{
                  margin: '0px',
                  width: '50%',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
              />
              <ButtonCommon
                value={
                  isActionButtonEdit
                    ? t('fx_update_order_label')
                    : t('fx_add_to_order_label')
                }
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: '50%',
                }}
                onClick={handleAddToOrder}
                isUseKeyDown={false}
              />
            </NextWrap>
          </Fade>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  display: ${(props) => props.$isHidden && 'none!important'};

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    // begin - fit on bottom
    // & .modal-dialog {
    //   position: absolute;
    //   margin: 0;
    //   top: 0px;
    //   height: 100vh;
    //   width: 100%;
    //   max-width: 100%;
    //   align-items: end;
    //   transition: ease-out 0.3s !important;
    //   transform: none;

    //   & .modal-content {
    //     padding: 0px;
    //     border-radius: 0px;
    //     box-shadow: none;
    //     background: var(--ds-c-white);
    //     width: 100%;
    //     height: fit-content;
    //     margin-inline: auto;
    //     border: none;
    //     border-radius: 8px 8px 0 0;
    //   }
    // }
    // end - fit on bottom
    & .modal-dialog {
      transform: none;
    }
    & .modal-content {
      padding: 0px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 468px;
      height: fit-content;
      margin-inline: auto;
    }
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
    }
    & .modal-content {
      padding: 0px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 468px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  height: 57px;
  padding: 0;
  padding-inline: 16px;
  margin-bottom: 0;
  padding-bottom: 0px;
  border: none !important;
  background-color: var(--ds-c-white-default);
  display: flex;
  align-items: center;
  justify-content: start;
`;
const Rate = styled.p`
  margin: 0;
  padding: 0;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: var(--ds-c-grey-dark);
`;

const ModalBody = styled(Modal.Body)`
  padding: 0px;
  padding: 16px;
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  padding: 16px;
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
`;

const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const RoundNoteText = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  color: var(--ds-c-blue);
  padding: 4px;
  border-radius: 4px;
  margin-inline: 16px;
  margin-bottom: 24px;
  background: var(--ds-c-blue-remox-light);
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  background: var(--ds-c-white);
  font-size: 16px;
  line-height: 20px;
  position: unset;
  margin: 0px;
  height: 44px;
`;

export default CalculatorModal;
