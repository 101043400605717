import NotificationsEmptyIcon from 'assets/icons/notifications_empty.svg';
import {
  redirectToFxTransactionDetails,
  redirectToTransactionDetails,
  updateNotificationsToClearAll,
  updateNotificationsToReadAll,
  updateNotifyToReaded,
} from 'components/Profile/func';
import InformPopup from 'components/common/InformPopup';
import {
  NOTIFY_TYPE_FX_TRANSACTION,
  NOTIFY_TYPE_TRANSACTION,
} from 'constants/notify';
import { datePicker } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils } from 'utils';
import CustomNotificationActionDropdown from '../CustomNotificationActionDropdown';

const Notifications = () => {
  const { t } = useLang();
  const { locate } = useLang();
  const { state, dispatch } = useStore();
  const { notifications, currentUser } = state;
  const { token } = useAuth();
  const navigate = useNavigate();

  const [isShowDeleteNotificationPopup, setShowDeleteNotificationPopup] =
    useState(false);

  const handleNotifyItemOnClick = async (e, notiData) => {
    e.preventDefault();

    dispatch({ type: SET_SHOW_LOADING, payload: true });

    updateNotifyToReaded(currentUser, notiData);

    if (notiData?.type === NOTIFY_TYPE_TRANSACTION) {
      redirectToTransactionDetails(token, notiData, navigate);

      return;
    }

    if (notiData?.type === NOTIFY_TYPE_FX_TRANSACTION) {
      redirectToFxTransactionDetails(token, notiData, navigate);

      return;
    }
  };

  const handleReadAll = () => {
    updateNotificationsToReadAll(notifications, currentUser);

    setShowDeleteNotificationPopup(false);
  };

  const handleClearAll = () => {
    updateNotificationsToClearAll(notifications, currentUser);

    setShowDeleteNotificationPopup(false);
  };

  return (
    <>
      {isShowDeleteNotificationPopup && (
        <InformPopup
          isOpen={isShowDeleteNotificationPopup}
          text={t('popup_delete_notification_title')}
          content={t('popup_delete_notification_text')}
          buttonLabel1={t('button_no')}
          buttonLabel2={t('button_yes')}
          onClose={() => setShowDeleteNotificationPopup(false)}
          onClick={handleClearAll}
        />
      )}

      <NotificationsStyled>
        <Title>
          {t('label_notifications')}{' '}
          {Boolean(notifications?.length) &&
            arrayUtils.otherThanNull(notifications) && (
              <CustomNotificationActionDropdown
                onReadAll={handleReadAll}
                onClearAll={() => setShowDeleteNotificationPopup(true)}
              />
            )}
        </Title>
        <Desc>{t('label_notification_message')}</Desc>
        {/* eslint-disable-next-line no-extra-boolean-cast */}
        {Boolean(notifications?.length) ? (
          <NotificationsBody>
            {notifications?.map(
              ({ createdAt, message, icon, read, uuid }, index) => (
                <NotifyItem
                  key={uuid}
                  eventKey={index}
                  onClick={(e) =>
                    handleNotifyItemOnClick(e, notifications[index])
                  }
                  $isRead={!read}
                >
                  <NotifyItemTitle>
                    <img src={icon} width={18} height={18} alt="" />{' '}
                    {message[locate.toUpperCase()].title}{' '}
                    {!read && <MarkUnread>●</MarkUnread>}
                  </NotifyItemTitle>
                  <NotifyItemDesc>
                    {message[locate.toUpperCase()].short}
                  </NotifyItemDesc>
                  <NotifyItemDateTime>
                    {`${datePicker.convert24hhmmTo12hhmm(
                      datePicker.timeUTCToTimeStr(new Date(parseInt(createdAt)))
                    )} - ${datePicker.dateUTCToDateStr(
                      new Date(parseInt(createdAt))
                    )}`}
                  </NotifyItemDateTime>
                </NotifyItem>
              )
            )}
          </NotificationsBody>
        ) : (
          <NotificationsEmpty>
            <img src={NotificationsEmptyIcon} width={153} height={153} alt="" />
            <span
              dangerouslySetInnerHTML={{
                __html: t('label_empty_notification'),
              }}
            />
          </NotificationsEmpty>
        )}
      </NotificationsStyled>
    </>
  );
};

const NotificationsStyled = styled.div`
  display: inline-block;
  width: 438px;
  height: fit-content;
`;
const Title = styled.h4`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  margin-top: 42px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  & span {
    font-size: 16px;
    color: var(--ds-c-sky);
    cursor: pointer;
    user-select: none;

    &:hover {
      color: var(--ds-c-sky-hover);
    }
  }
`;
const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
`;

const NotificationsBody = styled.div`
  width: 100%;
  height: fit-content;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--ds-c-grey-hover);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;
const NotificationsEmpty = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-top: 150px;

  & span {
    margin-top: 40px;
  }
`;

const NotifyItem = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 16px;
  background-color: ${(props) => (props.$isRead ? '#EEF2F5' : '#fff')};
  border-bottom: 1px solid #d0dce5;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;
const NotifyItemTitle = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0e1012;

  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & span {
    font-family: var(--ff-secondary);
    color: var(--ds-c-blue);
    font-weight: 700;
    margin-left: 4px;
  }

  & img {
    margin-right: 8px;
  }
`;
const MarkUnread = styled.span`
  font-size: 18px;
  color: var(--ds-c-red) !important;
`;
const NotifyItemDesc = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-dark);
  text-wrap: wrap;

  margin-bottom: 8px;
`;
const NotifyItemDateTime = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-neutral);
  text-align: right;

  margin-bottom: 0px;
`;

export default Notifications;
