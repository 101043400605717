import DeleteIcon from 'assets/ver2/icons/delete-black-icon.svg';
import EditIcon from 'assets/ver2/icons/edit-black-icon.svg';
import CalculatorModal from 'components/fx/CalculatorModal';
import { CURRENCY_FLAG_REFERENCES } from 'constants';
import { CURRENCY_NAME_REFERENCE } from 'constants/currency';
import {
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
} from 'constants/fx';
import { format } from 'helpers';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useCommonStore from 'hooks/useCommonStore';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const OrderList = ({ renderAddCurrencySection }) => {
  const { t } = useLang();

  const [currentOrderSelected, setCurrentOrderSelected] = useState(null);

  const { currencyOrders, deleteOrderInCurrencyOrders } =
    useFxFormAddTransactionStore();
  const { setLoading } = useCommonStore();

  const handleDeleteOrderItem = (order) => {
    deleteOrderInCurrencyOrders(order);
  };

  const handleEditOrderItem = (order) => {
    setLoading(true);
    setCurrentOrderSelected(order);
  };

  const handleOnCloseCalculatorModal = () => {
    setCurrentOrderSelected(null);
  };

  const renderCurrencyItem = (order) => {
    if (isEmpty(order)) return null;
    return (
      <CurrencyItemWrap>
        <GridItemWrap>
          <GridItem onClick={() => handleEditOrderItem(order)}>
            {' '}
            <FlagIcon
              style={{ position: 'unset' }}
              src={
                CURRENCY_FLAG_REFERENCES[
                  order?.currentCurrencySelected?.currencyCode
                ]
              }
            />
          </GridItem>
          <GridItem onClick={() => handleEditOrderItem(order)}>
            <CurrencyCodeBuyAmount>
              <CurrencyChip>
                {order?.currentCurrencySelected?.currencyCode}
              </CurrencyChip>
              <b>
                {format.toAmountCentStr(order[FX_CALCULATOR_FORM_BUY_NAME])}
              </b>
              <EditIconStyled
                src={EditIcon}
                width={20}
                height={20}
                alt=""
                onClick={() => handleEditOrderItem(order)}
              />
            </CurrencyCodeBuyAmount>
            <PayAmountText
              dangerouslySetInnerHTML={{
                __html: `AUD <b>${format.toAmountCentStr(
                  order[FX_CALCULATOR_FORM_PAY_NAME]
                )}</b>`,
              }}
            />
          </GridItem>
          <GridItem>
            <DeleteIconStyled
              src={DeleteIcon}
              width={20}
              height={20}
              alt=""
              onClick={() => handleDeleteOrderItem(order)}
              style={{
                display: currencyOrders?.length === 1 && 'none',
              }}
            />
          </GridItem>
        </GridItemWrap>
        <SolidLine />
        <CurrencyNameRate>
          <CurrencyNameText>
            {
              CURRENCY_NAME_REFERENCE[
                order?.currentCurrencySelected?.currencyCode
              ]
            }
          </CurrencyNameText>
          <RateText>{`(1 AUD = ${format.toAmountCentStr(
            order?.sellRate?.rate
          )} ${order?.currentCurrencySelected?.currencyCode})`}</RateText>
        </CurrencyNameRate>
      </CurrencyItemWrap>
    );
  };
  const renderCurrencyOrders = () => {
    if (isEmpty(currencyOrders)) return null;
    return (
      <CurrencyOrdersWrap>
        {currencyOrders?.map((order, index) => (
          <Fragment key={index}>{renderCurrencyItem(order)}</Fragment>
        ))}
      </CurrencyOrdersWrap>
    );
  };

  return (
    <>
      {!isEmpty(currentOrderSelected) && (
        <CalculatorModal
          isOpen={!isEmpty(currentOrderSelected)}
          onClose={handleOnCloseCalculatorModal}
          orderEdit={currentOrderSelected}
        />
      )}

      <OrderListStyled>
        <NoteLabel>
          {t('label_currencies_quantity_per_txn', {
            number: 3,
          })}
        </NoteLabel>
        {renderCurrencyOrders()}
        {renderAddCurrencySection()}
        <DashedLine />
      </OrderListStyled>
    </>
  );
};

const OrderListStyled = styled.div`
  & .table-responsive {
    width: calc(100% + 32px);
    margin-inline: -16px;
    height: 100%;

    & table {
      margin: 0;
    }
  }
`;

const NoteLabel = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  margin-bottom: 12px;
`;

const EditIconStyled = styled.img`
  filter: var(--ds-f-c-yellow);
  &:hover {
    filter: var(--ds-f-c-yellow-hover);
  }
`;
const DeleteIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

const CurrencyOrdersWrap = styled.div`
  width: 100%;
  height: fit-content;
`;
const CurrencyItemWrap = styled.div`
  width: 100%;
  min-height: 90px;
  height: 90px;

  box-shadow: 0px 4px 15px 0px #0000001a;
  border-radius: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 24px;
  }
`;
const CurrencyNameRate = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
`;
const GridItemWrap = styled.div`
  width: 100%;
  min-height: 60px;
  height: 60px;
  padding: 8px 10px;
  display: grid;
  grid-template-columns: 30px calc(60% - 30px) calc(40% - 30px);
  grid-gap: 12px;
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  padding-block: 0px;

  &:nth-child(1) {
    justify-content: start;
    align-items: center;
  }
  &:nth-child(2) {
  }
  &:last-child {
    justify-content: end;
    align-items: center;
  }
`;
const CurrencyNameText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  margin: 0;
  padding: 0;
`;
const RateText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  margin: 0;
  padding: 0;
`;
const CurrencyCodeBuyAmount = styled.div`
  ${paragraphMixin};
  font-weight: 500;

  display: flex;
  gap: 4px;
`;
const PayAmountText = styled.p`
  ${paragraphMixin};
  font-weight: 500;
`;

const CurrencyChip = styled.span`
  width: fit-content;
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;
const FlagIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
`;

const SolidLine = styled.div`
  height: 1px;
  width: 100%;
  background: var(--ds-bg-2);
`;
const DashedLine = styled.div`
  border-bottom: 1px dashed var(--ds-bg-2);
  margin-bottom: 24px;
`;

export default OrderList;
