import {
  fetchAllDiscounts,
  getDiscountsTypeOther,
  getDiscountsTypePromotion,
} from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  OFFERS_TABS,
  PROFILE_PREFIX,
  PROMOTION,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import { useEffect, useRef, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import { domUtils } from 'utils';
import DiscountsTab from '../../DiscountsTab';

const OffersMobile = ({ currentTab, setCurrentTab }) => {
  const { t } = useLang();
  const { token } = useAuth();

  const [currentSubTab, setCurrentSubTab] = useState(0);
  const [promotion, setPromotion] = useState(null);
  const [voucher, setVoucher] = useState(null);

  const isFetching = useRef();

  useEffect(() => {
    if (token && !voucher && !promotion && !isFetching.current) {
      isFetching.current = true;

      fetchAllDiscounts(token, (data) => {
        setPromotion(getDiscountsTypePromotion(data));

        setVoucher(getDiscountsTypeOther(data));

        isFetching.current = false;
      });
    }
  }, [token]);

  const getNumOfItem = (items) =>
    items?.length > 0 ? ' (' + items?.length + ')' : '';

  const tabContentReference = {
    0: <DiscountsTab items={promotion} type={PROMOTION} />,
    1: <DiscountsTab items={voucher} />,
  };

  const handleTabOnClick = (index) => {
    setCurrentSubTab(index);

    domUtils.changeAddressBarUrlWithoutReloadPage({
      prefix: PROFILE_PREFIX,
    });
  };

  return (
    <>
      <style>
        {`
          .offers-mobile-offcanvas {
            width: 100%!important;
            height: calc(100% - 70px);
            border: none!important;
          }
          .offers-mobile-offcanvas-backdrop {
            opacity: 0!important;
          }
        `}
      </style>

      <OffersMobileStyled>
        <NavbarStyled
          expand={false}
          expanded={currentTab === 3}
          onToggle={() => setCurrentTab(null)}
        >
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="offers-mobile-offcanvas"
            backdropClassName="offers-mobile-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_offers')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              <TabWrap>
                {OFFERS_TABS.map((tab, index) => (
                  <ButtonCommon
                    key={tab.label}
                    value={
                      t(tab.label) +
                      (index === 0
                        ? getNumOfItem(promotion)
                        : getNumOfItem(voucher))
                    }
                    background={
                      currentSubTab === index
                        ? 'var(--c-tab-active-2)'
                        : 'transparent'
                    }
                    color={'var(--ds-c-blue)'}
                    styles={{
                      width: '50%',
                      borderRadius: '0px',
                      height: '36px',
                      float: 'left',
                      color: 'var(--ds-c-blue)',
                      ...(currentSubTab !== index && {
                        fontWeight: 500,
                        borderBottom: '2px solid #FFFFFF',
                      }),
                      ...(currentSubTab === index && {
                        borderBottom: '2px solid var(--ds-c-blue)',
                      }),
                      paddingInline: 0,
                    }}
                    onClick={() => handleTabOnClick(index)}
                  />
                ))}
              </TabWrap>
              <ContentBody>{tabContentReference[currentSubTab]}</ContentBody>

              <ButtonActions>
                <ButtonCommon
                  value={t('button_back')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    margin: '0px',
                    width: '100%',
                  }}
                  onClick={() => setCurrentTab(null)}
                />
              </ButtonActions>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </OffersMobileStyled>
    </>
  );
};

const OffersMobileStyled = styled.div`
  position: relative;
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  position: absolute;
  top: -40px;
  right: 0;
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: unset;
    right: unset;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)`
  margin-top: 70px;
`;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 56px;
  padding: 16px 24px;
  border-bottom: none;

  & button {
    display: none;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #7a8189;
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding-inline: 24px;
  padding-block: 0px;
  margin-bottom: 78px;
`;

const ButtonActions = styled.div`
  position: absolute;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

const TabWrap = styled.div`
  height: 44px;
  background-color: transparent;
  color: var(--ds-c-blue);
  padding: 4px;
`;

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 80px;
`;

export default OffersMobile;
