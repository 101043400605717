/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import CollapseSidebarProcessStepsLayout from 'components/common/CollapseSidebarProcessStepsLayout';
import InformPopup from 'components/common/InformPopup';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import ConfirmInformation from 'components/fx/FxFormAddTransaction/ConfirmInformation';
import { FORM_TRANSACTION_SUMMARY_MODAL } from 'components/fx/FxFormAddTransaction/FormTransactionSummary';
import Order from 'components/fx/FxFormAddTransaction/Order';
import PaymentMethod from 'components/fx/FxFormAddTransaction/PaymentMethod';
import PickupDate from 'components/fx/FxFormAddTransaction/PickupDate';
import PickupLocation from 'components/fx/FxFormAddTransaction/PickupLocation';
import ConfirmInformationAsGuest from 'components/fx/FxFormAddTransactionAsGuest/ConfirmInformationAsGuest';
import DocumentUpload from 'components/fx/FxFormAddTransactionAsGuest/DocumentUpload';
import {
  DESKTOP_MIN_WIDTH,
  FOREIGN_EXCHANGE_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  HOME_URL,
  ORDER_EMAIL,
  STATUS_CANCELLED,
  STATUS_FAIL,
  STATUS_SUCCESS,
} from 'constants';
import Cookies from 'helpers/cookies';
import useFxAddTransaction, {
  FX_MAKE_PAYMENT_KEY,
} from 'hooks/fx/useFxAddTransaction';
import useFxCheckInactivityTime from 'hooks/fx/useFxCheckInactivityTime';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useFxPayment from 'hooks/fx/useFxPayment';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import useAuth from 'hooks/useAuth';
import useCommonStore from 'hooks/useCommonStore';
import useCountdownToAction from 'hooks/useCountdownToAction';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import usePortalModal from 'hooks/usePortalModal';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FX_ADD_TRANSACTION_EXCHANGE_RATE_OUTDATE_COUNTDOWN_DEFAULT = 600; //600s = 10m

const FxAddTransactionPage = () => {
  const { t, locate } = useLang();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { showPortalModalName } = usePortalModal();

  const [isOpenInformPopup, setOpenInformPopup] = useState(false);

  const { isFxAddTransactionPage, isFxAddTransactionAsGuestPage } = usePage();
  const {
    isDisabled,
    getNavbarsAndBodyContentOfSidebar,
    getNavbarsAndBodyContentOfSidebarAsGuest,
    fxAddTransactionTotalNumberOfSteps,
    orderRef,
    paymentStatus,
    isMakePayment,
    removeFxMakePayment,
    isFxAsGuest,
    guestBuyAmount,
    guestPayAmount,
    guestCurrencyCode,
  } = useFxAddTransaction();
  const {
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    resetAddTransactionInformation,
    refreshCurrencyOrdersByExchangeRateOutdate,
    currencyOrders,
    setTransactionDetails,
    tokenTransaction,
    transactionInformationStoreTransformInitial,
    transactionDetails,
    fromWebflowRedirectToOrderInitial,
    confirmInformationEmail,
  } = useFxFormAddTransactionStore();
  const { paymentMintProcess } = useFxPayment();
  const { setPending } = useCommonStore();

  useSmartLook({
    isInit: !isEmpty(confirmInformationEmail),
    email: confirmInformationEmail,
  });

  const {
    isOpenInactivityPopup,
    FxInactivityTimePopup,
    handleOnClickInactivityTimePopup,
  } = useFxCheckInactivityTime();

  const navbarsAndBodyContentOfSidebar = !isFxAsGuest
    ? getNavbarsAndBodyContentOfSidebar()
    : getNavbarsAndBodyContentOfSidebarAsGuest();

  /* BEGIN - check rate outdate */
  const handleExchangeRateOutdate = async (loopCallback) => {
    refreshCurrencyOrdersByExchangeRateOutdate({
      callback: loopCallback,
    });
  };
  useCountdownToAction(
    0,
    (isFxAddTransactionPage || isFxAddTransactionAsGuestPage) &&
      !isEmpty(currencyOrders) &&
      !document.getElementsByClassName(FORM_TRANSACTION_SUMMARY_MODAL)?.[0],
    handleExchangeRateOutdate,
    [isFxAddTransactionPage, isFxAddTransactionAsGuestPage, currencyOrders],
    FX_ADD_TRANSACTION_EXCHANGE_RATE_OUTDATE_COUNTDOWN_DEFAULT,
    true
  );
  /* END - check rate outdate */

  /* BEGIN - payment mint */
  useEffect(() => {
    if (tokenTransaction && orderRef) {
      setPending(true);
      paymentMintProcess(orderRef, tokenTransaction, confirmInformationEmail);
    }
  }, [orderRef, tokenTransaction]);
  /* END - payment mint */

  /* BEGIN - add transaction information initial */
  useEffect(() => {
    if (orderRef) {
      transactionInformationStoreTransformInitial({
        orderRef,
        errorCallback: handleOnClickCancelPopup,
      });

      let currentStep = 0;

      if (isMakePayment) {
        currentStep = fxAddTransactionTotalNumberOfSteps - 1;

        if (paymentStatus === STATUS_CANCELLED) {
          currentStep = fxAddTransactionTotalNumberOfSteps - 1;
          setAddTransactionCurrentStep(currentStep);
          setOpenInformPopup(true);
          return;
        }

        if (paymentStatus === STATUS_FAIL) {
          currentStep = fxAddTransactionTotalNumberOfSteps - 1;
          setAddTransactionCurrentStep(currentStep);
          setOpenInformPopup(true);
          return;
        }

        if (paymentStatus === STATUS_SUCCESS) {
          currentStep = fxAddTransactionTotalNumberOfSteps;
          setAddTransactionCurrentStep(currentStep);
          removeFxMakePayment();
          return;
        }

        setAddTransactionCurrentStep(currentStep);

        if (!orderRef) {
          removeFxMakePayment();
          window.location.reload();
          return;
        }
        return;
      } else {
        if (paymentStatus === STATUS_CANCELLED) {
          currentStep = fxAddTransactionTotalNumberOfSteps - 1;
          setAddTransactionCurrentStep(currentStep);
          setOpenInformPopup(true);
          return;
        }

        if (paymentStatus === STATUS_FAIL) {
          currentStep = fxAddTransactionTotalNumberOfSteps - 1;
          setAddTransactionCurrentStep(currentStep);
          setOpenInformPopup(true);
        }

        if (paymentStatus === STATUS_SUCCESS) {
          currentStep = fxAddTransactionTotalNumberOfSteps;
          setAddTransactionCurrentStep(currentStep);
          removeFxMakePayment();
          return;
        }
      }
    }
  }, [orderRef, paymentStatus, isMakePayment, transactionDetails]);
  /* END - add transaction information initial */

  /* BEGIN - from webflow to order initial */
  useEffect(() => {
    if (guestCurrencyCode) {
      fromWebflowRedirectToOrderInitial({
        currencyCode: guestCurrencyCode,
        payAmount: guestPayAmount,
        buyAmount: guestBuyAmount,
      });
    }
  }, [isFxAsGuest, guestCurrencyCode, guestPayAmount, guestBuyAmount]);
  /* END - from webflow to order initial */

  const handleOnClickCancelPopup = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    resetAddTransactionInformation();
    setAddTransactionCurrentStep(0);
    setTransactionDetails(null);
    if (isFxAsGuest) {
      window.location.href = `${HOME_URL[theme]}${FOREIGN_EXCHANGE_PREFIX}?lang=${locate}`;
    } else {
      navigate(`${FX_TRANSACTIONS_PREFIX}`);
    }
  };
  const handleOnToggleCancelPopup = () => {
    showPortalModalName({
      name: PORTAL_NAME_MODAL_DEFINE.CANCEL_FX_ADD_TRANSACTION_MODAL,
      title: t('popup_cancel_fx_add_transaction'),
      content: t('popup_cancel_fx_add_transaction_desc'),
      action: handleOnClickCancelPopup,
      actionClose: () => {},
    });
  };

  const handleOnClickViewPickupLocationPopup = () => {
    setAddTransactionCurrentStep(1);
  };
  const handleOnViewPickupLocation = () => {
    showPortalModalName({
      name: PORTAL_NAME_MODAL_DEFINE.PICKUP_LOCATION_PREVIEW_MODAL,
      action: handleOnClickViewPickupLocationPopup,
    });
  };

  const stepContentReferenceAsRegister = {
    0: (
      <Order
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    1: (
      <PickupLocation
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    2: (
      <PaymentMethod
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    3: (
      <PickupDate
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    4: (
      <ConfirmInformation
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    5: (
      <ConfirmInformation
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
  };
  const stepContentReferenceAsGuest = {
    0: (
      <Order
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    1: (
      <PickupLocation
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    2: (
      <PaymentMethod
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    3: (
      <PickupDate
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    4: (
      <ConfirmInformationAsGuest
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    5: (
      <DocumentUpload
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
    6: (
      <DocumentUpload
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    ),
  };
  const stepContentReference = !isFxAsGuest
    ? stepContentReferenceAsRegister
    : stepContentReferenceAsGuest;

  return (
    <>
      {isFxAsGuest && isOpenInactivityPopup && (
        <FxInactivityTimePopup
          isOpen={isFxAsGuest && isOpenInactivityPopup}
          onClick={handleOnClickInactivityTimePopup}
          onClose={handleOnClickCancelPopup}
        />
      )}

      {isOpenInformPopup && (
        <InformPopup
          isOpen={isOpenInformPopup}
          imageSrc={NoticeIcon}
          imagesStyles={{
            width: '124px',
            height: '100px',
            marginBottom: '16px',
          }}
          text={t('popup_payment_fail')}
          textStyle={{
            color: 'var(--ds-c-grey-dark)',
          }}
          content={t('popup_payment_fail_desc')}
          buttonLabel1={null}
          buttonLabel2={t('button_ok')}
          onClose={() => setOpenInformPopup(false)}
          onClick={() => setOpenInformPopup(false)}
          isBackdropOverlap={false}
        />
      )}

      {currentStep <= fxAddTransactionTotalNumberOfSteps && (
        <AddTransactionStyled>
          <CollapseSidebarProcessStepsLayout
            titleSidebar={t('fx_add_transaction_buy_currency_label')}
            navbars={navbarsAndBodyContentOfSidebar}
            currentStep={currentStep}
            stepContentReference={stepContentReference}
            isPreview={isDisabled}
            isHideProcessSteps={isDisabled}
            isSuccess={currentStep === fxAddTransactionTotalNumberOfSteps}
          />
        </AddTransactionStyled>
      )}
    </>
  );
};

const AddTransactionStyled = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 16px;
  height: calc(100vh - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin: unset;
    margin-top: 0px;
    padding: unset;
    padding-top: 96px;
    height: fit-content;
    min-height: 100vh;
  }
`;

export default FxAddTransactionPage;
