/* eslint-disable no-unused-vars */
import ArrowDownIcon from 'assets/icons/arrow-down-icon.svg';
import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxIcon from 'assets/icons/radiobox-remox-icon.svg';
import ClockIcon from 'assets/ver2/icons/clock-small-blue-icon.svg';
import LocationIcon from 'assets/ver2/icons/location-outline-blue-icon.svg';
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_PICKUP_LOCATION,
  FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { isEmpty } from 'lodash';
import { paragraphMixin } from 'styles';

const CustomPickupLocationAccordion = ({
  pickupLocationData,
  information,
  setInformation,
}) => {
  const { t } = useLang();
  const { isDesktop } = useDetectDevice();

  const [currentPickupLocationExpanded, setCurrentPickupLocationExpanded] =
    useState(null);

  const { getPickupLocationFullAddress, getPickupLocationOpeningHours } =
    useFxAddTransaction();
  const { showPortalModalName } = usePortalModal();
  const { getPickupLocationName } = useFxAddTransaction();

  const currentPickupLocationSelected =
    information[FX_ADD_TRANSACTION_PICKUP_LOCATION];
  const currentPickupLocationTypeSelected =
    information[FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE];

  const isItemExpanded = (item) =>
    item?.name === currentPickupLocationExpanded?.name;

  const isItemSelected = (item) =>
    item?.name === currentPickupLocationSelected?.name;

  const preventOnClickToAction = (e) => {
    if (isEmpty(e)) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnSelectAccordionItem = (item) => {
    let newInformation = { ...information };

    if (isItemSelected(item)) {
      newInformation = {
        ...newInformation,
        [FX_ADD_TRANSACTION_PICKUP_LOCATION]: null,
      };
      setInformation(newInformation);
      return;
    }

    newInformation = {
      ...newInformation,
      [FX_ADD_TRANSACTION_PICKUP_LOCATION]: item,
    };
    setInformation(newInformation);
  };
  const handleOnExpandAccordionItem = (item) => {
    if (!isDesktop) {
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.PICKUP_LOCATION_VIEW_DETAILS_MODAL,
        title: item,
      });
      return;
    }
    if (isItemExpanded(item)) {
      setCurrentPickupLocationExpanded(null);
      return;
    }
    setCurrentPickupLocationExpanded(item);
  };

  const renderPickupLocationAddress = (item) => {
    const fullAddress = getPickupLocationFullAddress(item);
    const direction = item?.direction;

    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
        }}
      >
        <FieldIcon>
          <img src={LocationIcon} width={16} height={16} alt="" />
        </FieldIcon>
        <FieldValue>
          {fullAddress || '-'}
          {direction && (
            <>
              <br />
              <span>{item?.direction}</span>
            </>
          )}
        </FieldValue>
      </FieldWrap>
    );
  };
  const renderPickupLocationOpeningHours = (item) => {
    const openingHoursMockup = getPickupLocationOpeningHours(item);

    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
        }}
        style={{
          marginTop: '10px',
        }}
      >
        <FieldIcon>
          <img src={ClockIcon} width={16} height={16} alt="" />
        </FieldIcon>
        <FieldValue style={{ textTransform: 'initial' }}>
          {t('label_opening_hours')}
          <br />
          <span dangerouslySetInnerHTML={{ __html: openingHoursMockup }} />
        </FieldValue>
      </FieldWrap>
    );
  };

  return (
    <AccordionStyled>
      {pickupLocationData.map((item, index) => (
        <AccordionItem
          key={item.name}
          eventKey={index}
          $isSelected={isItemSelected(item)}
        >
          <AccordionHeader $isSelected={isItemSelected(item)}>
            <AccordionHeaderTextViewWrap>
              <AccordionHeaderText onClick={preventOnClickToAction}>
                {getPickupLocationName(item, currentPickupLocationTypeSelected)}
              </AccordionHeaderText>
              <AccordionHeaderView
                $isExpanded={isItemExpanded(item)}
                onClick={() => handleOnExpandAccordionItem(item)}
              >
                <p>{t('button_pickup_location_view_detail')}</p>
                <img src={ArrowDownIcon} />
              </AccordionHeaderView>
            </AccordionHeaderTextViewWrap>
            {isItemExpanded(item) && (
              <AccordionHeaderBody>
                {isItemExpanded(item) && (
                  <AccordionHeaderBodyWrap>
                    <Divider />
                    {renderPickupLocationAddress(item)}
                    {renderPickupLocationOpeningHours(item)}
                  </AccordionHeaderBodyWrap>
                )}
              </AccordionHeaderBody>
            )}
            <SelectRadioMask
              onClick={() => handleOnSelectAccordionItem(item)}
            />
          </AccordionHeader>
        </AccordionItem>
      ))}
    </AccordionStyled>
  );
};

const AccordionStyled = styled(Accordion)`
  width: 100%;
  margin-top: 0px;
`;

const AccordionItem = styled(Accordion.Item)`
  border: none;
  margin-bottom: 0px;
  border-bottom: 1px solid var(--ds-bg-2);
  background-color: ${(props) =>
    props.$isSelected
      ? 'rgba(212, 220, 229, 0.3)'
      : 'var(--ds-c-white)'}!important;
`;

const AccordionHeader = styled(Accordion.Header)`
  height: fit-content;
  padding: 16px;
  box-shadow: none;
  border-radius: 0px;

  & button {
    padding: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    display: block;
    cursor: default !important;

    &:focus,
    &:hover {
      z-index: 1;
    }

    &::after {
      cursor: pointer;
    }
    &::after,
    &.collapsed::after {
      background-image: ${(props) =>
        props.$isSelected
          ? `url(${RadioCheckedIcon})`
          : `url(${RadioboxIcon})`};
      background-position: center;
      background-size: 25 25;

      position: absolute;
      top: 10px;
      right: 0px;
    }
    &::after,
    &:not(.collapsed)::after {
      background-image: ${(props) =>
        props.$isSelected
          ? `url(${RadioCheckedIcon})`
          : `url(${RadioboxIcon})`};
      background-position: center;
      background-size: 25 25;

      position: absolute;
      top: 10px;
      right: 0px;
    }
  }
`;
const AccordionHeaderTextViewWrap = styled.div``;
const AccordionHeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  padding: 0;
  padding-right: 20px;
  margin: 0;
  margin-bottom: 4px;
  width: calc(100% - 40px);
  height: fit-content;
  display: flex;
  align-items: center;
  cursor: default;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const AccordionHeaderView = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 40px);
  cursor: pointer !important;

  & p {
    ${paragraphMixin};
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: var(--ds-c-blue-hyperlink-default);
  }

  & img {
    filter: var(--ds-f-c-blue-hyperlink);
    transform: ${(props) => props.$isExpanded && 'rotate(180deg)'};
    transition: 0.2s ease;
  }
`;
const SelectRadioMask = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 1;
  width: 20px;
  height: 40px;
  cursor: pointer;
`;

const AccordionHeaderBody = styled.div`
  background: var(--ds-c-white);
  width: calc(100% + 32px);
  margin-inline: -16px;
  padding-inline: 16px;
  margin-bottom: -16px;
  padding-bottom: 16px;
`;
const AccordionHeaderBodyWrap = styled.div`
  width: 100%;
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props?.styles?.alignItems || 'center'};
  padding: 0px;
  justify-content: start;
  width: ${(props) => (props.$isInline ? '207px' : '100%')};
  height: fit-content;
  min-height: 40px;
  float: ${(props) => props.$isFloatLeft && 'left'};
  margin-top: 16px;
  margin-right: ${(props) => props.$isFloatLeft && '32px'};
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  text-transform: uppercase;
  color: var(--ds-c-blue);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 4px;

  & span {
    text-transform: initial;
    font-weight: 500;
    color: var(--ds-c-grey-dark);
    margin-top: 4px;
  }
`;

const Divider = styled.div`
  width: calc(100% + 32px);
  margin-inline: -16px;
  margin-block: 16px;
  border-bottom: 1px solid var(--ds-bg-2);
`;

export default CustomPickupLocationAccordion;
