import { PROFILE_PREFIX, PROFILE_TABS } from 'constants';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { domUtils } from 'utils';
import Notifications from '../Notifications';
import PersonalDetails from '../PersonalDetails';
import Security from '../Security';
import Offers from '../Offers';
import ButtonCommon from 'components/common/ButtonCommon';

const ProfileBody = () => {
  const { t, locate } = useLang();
  const { state } = useStore();
  const { notifications } = state;
  const query = useQuery();
  const tab = query.get('tab');

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (tab) {
      setCurrentTab(parseInt(tab));
    }
  }, [tab]);

  const tabContentReference = {
    0: <PersonalDetails />,
    1: <Security />,
    2: <Notifications />,
    3: <Offers />,
  };

  const handleTabOnClick = (index) => {
    setCurrentTab(index);

    domUtils.changeAddressBarUrlWithoutReloadPage({
      prefix: PROFILE_PREFIX,
    });
  };

  return (
    <ProfileBodyStyled>
      <TabWrap>
        {PROFILE_TABS.map((tab, index) => (
          <ButtonCommon
            key={tab.label}
            value={currentTab === index ? '' : t(tab.label)}
            color="#4965A0"
            background={
              currentTab === index ? 'var(--c-tab-active)' : 'transparent'
            }
            styles={{
              paddingInline: 0,
              width: '25%',
              height: '36px',
              float: 'left',
              borderRadius: '100px',
              color: 'var(--ds-c-blue)',
              ...(currentTab !== index && {
                fontWeight: 500,
              }),
            }}
            buttonIconStyles={{
              width: '25%',
              height: '36px',
              float: 'left',
              borderRadius: '100px',
            }}
            iconSrc={currentTab === index && tab.iconFill}
            onClick={() => handleTabOnClick(index)}
          />
        ))}
      </TabWrap>
      {Boolean(notifications) &&
        Boolean(notifications?.filter(({ read }) => !read)?.length) && (
          <HaveNotify $isViLang={locate === 'vi'} $isActive={currentTab === 2}>
            {notifications.filter(({ read }) => !read)?.length}
          </HaveNotify>
        )}
      <ContentBody>{tabContentReference[currentTab]}</ContentBody>
    </ProfileBodyStyled>
  );
};

const ProfileBodyStyled = styled.div`
  position: relative;
  margin-top: 42px;
`;
const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const HaveNotify = styled.span`
  font-family: var(--ff-primary);
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: var(--ds-c-white);
  text-decoration: none;
  text-align: center;
  padding-right: 0px;

  position: absolute;
  top: 8px;
  right: ${(props) =>
    props.$isActive
      ? 'calc(25% + 64px)'
      : props.$isViLang
      ? 'calc(25% + 22px)'
      : 'calc(25% + 16px)'}!important;
  width: 16px;
  height: 16px;
  background: #e72631;
  border-radius: 100%;
`;

const TabWrap = styled.div`
  height: 44px;
  background-color: var(--ds-c-grey-disabled);
  border-radius: 100px;
  padding: 4px;
`;

export default ProfileBody;
