import {
  SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
  SET_SERVICE_ALERTS_BY_PAYMENTS,
} from 'store/action';
import useStore from '../useStore';

const useServiceAlertsStore = () => {
  const { state, setState } = useStore();
  const { serviceAlertsByCountryCode, serviceAlertsByPayments } = state;

  const setServiceAlertsByCountryCode = (payload) =>
    setState({ action: SET_SERVICE_ALERTS_BY_COUNTRY_CODE, payload });
  const resetServiceAlertsStoreByPayoutMethodItem = () => {
    setState({
      action: SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
      payload: {
        ...serviceAlertsByCountryCode,
        serviceAlertsByPayoutMethodItem: null,
        showValidateBankAccountModal: null,
        serviceAlertsByPayoutMethodItemChild: null,
        serviceAlertsByCurrencyCode1: null,
        serviceAlertsByCurrencyCode2: null,
        serviceAlertsByRangeOfCurrencyCode1: null,
        serviceAlertsByRangeOfCurrencyCode2: null,
      },
    });
  };
  const resetServiceAlertsByCurrencies = () => {
    setState({
      action: SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
      payload: {
        ...serviceAlertsByCountryCode,
        serviceAlertsByCurrencyCode1: null,
        serviceAlertsByCurrencyCode2: null,
        serviceAlertsByRangeOfCurrencyCode1: null,
        serviceAlertsByRangeOfCurrencyCode2: null,
      },
    });
  };
  const resetServiceAlertsByRangeOfCurrencyCode1 = () => {
    setState({
      action: SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
      payload: {
        ...serviceAlertsByCountryCode,
        serviceAlertsByRangeOfCurrencyCode1: null,
      },
    });
  };
  const resetServiceAlertsByRangeOfCurrencyCode2 = () => {
    setState({
      action: SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
      payload: {
        ...serviceAlertsByCountryCode,
        serviceAlertsByRangeOfCurrencyCode2: null,
      },
    });
  };

  const setServiceAlertsByPayments = (payload) =>
    setState({ action: SET_SERVICE_ALERTS_BY_PAYMENTS, payload });

  return {
    serviceAlertsByCountryCode,
    serviceAlertsByPayments,
    setServiceAlertsByCountryCode,
    setServiceAlertsByPayments,
    resetServiceAlertsStoreByPayoutMethodItem,
    resetServiceAlertsByCurrencies,
    resetServiceAlertsByRangeOfCurrencyCode1,
    resetServiceAlertsByRangeOfCurrencyCode2,
  };
};

export default useServiceAlertsStore;
