import { fetchRate } from 'components/FormAddTransaction/func';
import { fetchCurrentFee } from 'components/Transactions/func';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  RESET_BACKDROP_STORE,
} from 'constants';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';

export const ADD_TRANSACTION_CURRENCY_NAME = 'add-transaction-currency-field';

const CustomCurrencyDropdown = ({
  sendName,
  receiverName,
  countryConfig,
  maxSendLimit,
  receiveLimits,
  currentRateValue,
  setCurrentRateValue,
  information,
  setInformation,
  validation,
  setValidation,
  currentCurrencySelected,
  currencyProp,
  rateProp,
  isDisabled,
  handleSendAmountOnChange,
  handleGetReceiverAmountValidation,
  isFetching,
  setFetching,
  setOpenDiscountImportantPopup,
}) => {
  const { state, dispatch } = useStore();
  const { addTransactionInformation, backdrop } = state || {};
  const { paymentMethod } = addTransactionInformation || {};

  const { discountApplied } = paymentMethod || {};

  const dmCode =
    addTransactionInformation?.receiverPayoutMethod[
      ADD_TRANSACTION_PAYOUT_METHOD_NAME
    ];
  const countryCode = countryConfig?.countryCode;

  const { setReceivingAmount } = useFormAddTransactionStore();

  const {
    serviceAlertsByCountryCode,
    resetServiceAlertsByRangeOfCurrencyCode1,
    resetServiceAlertsByRangeOfCurrencyCode2,
  } = useServiceAlertsStore();
  const { serviceAlertsByCurrencyCode1, serviceAlertsByCurrencyCode2 } =
    serviceAlertsByCountryCode || {};
  const { checkByCurrencyCode } = useCheckServiceAlerts();
  const { setValidationOfServiceAlertsByCurrencyCode } = checkByCurrencyCode();
  const serviceAlertsByCurrencyCode =
    currencyProp === 'currentCurrencySelected'
      ? serviceAlertsByCurrencyCode1
      : serviceAlertsByCurrencyCode2;
  const resetServiceAlertsByRangeOfCurrencyCode =
    currencyProp === 'currentCurrencySelected'
      ? resetServiceAlertsByRangeOfCurrencyCode1
      : resetServiceAlertsByRangeOfCurrencyCode2;
  const { type } = serviceAlertsByCurrencyCode || {};

  useEffect(() => {
    const sendAmount = Number(information[sendName] || 0) || '';
    const receiverAmount = Number(information[receiverName] || 0) || '';
    const rate = information[rateProp];
    const fee = information?.feeAmount;
    if (
      sendAmount &&
      receiverAmount &&
      !isEmpty(rate) &&
      !isEmpty(currentCurrencySelected) &&
      !isEmpty(fee)
    ) {
      setReceivingAmount({
        [sendName]: sendAmount,
        [receiverName]: receiverAmount,
        [currencyProp]: currentCurrencySelected,
      });
    }
  }, [information, currentCurrencySelected]);

  useEffect(() => {
    setValidationOfServiceAlertsByCurrencyCode({
      currencyCode: currentCurrencySelected?.currencyCode,
      type,
      callback: (msg) => {
        setValidation({
          ...validation,
          [receiverName]: msg,
        });

        resetServiceAlertsByRangeOfCurrencyCode();
      },
    });
  }, [type, currentCurrencySelected]);

  useEffect(() => {
    return () => {
      dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOnlyReceiverCurrency = receiveLimits?.length === 1;

  const { debounce: handleFetchCurrentFee } = useDebounceCallback(
    (information, setInformation, countryCode, dmCode) => {
      fetchCurrentFee(
        information,
        setInformation,
        countryCode,
        dmCode,
        maxSendLimit,
        () => setFetching(false)
      );

      setValidationOfServiceAlertsByCurrencyCode({
        currencyCode: currentCurrencySelected?.currencyCode,
        type,
        callback: (msg) => {
          setValidation({
            ...validation,
            [receiverName]: msg,
          });

          resetServiceAlertsByRangeOfCurrencyCode();
        },
      });
    },
    0
  );

  useEffect(() => {
    const sendAmount = Number(information[sendName] || 0) || '';
    const receiverAmount = Number(information[receiverName] || 0) || '';

    if (receiverAmount && !isDisabled && Boolean(currentRateValue)) {
      const [sendValue, receiverValue] = handleSendAmountOnChange(
        sendAmount,
        true
      );

      const newInformation = {
        ...information,
        [sendName]: sendValue,
        [receiverName]: receiverValue,
      };

      let newValidation = {
        ...validation,
        [receiverName]: '',
      };

      newValidation = handleGetReceiverAmountValidation(
        newInformation,
        newValidation
      );
      setValidation(newValidation);

      setFetching(true);
      handleFetchCurrentFee(
        newInformation,
        setInformation,
        countryCode,
        dmCode
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRateValue, isDisabled]);

  const handleCurrencySelected = async (currency) => {
    if (isFetching) return;

    if (currency?.currencyCode !== currentCurrencySelected?.currencyCode) {
      setFetching(true);
    }

    const newInformation = {
      ...information,
      [currencyProp]: currency,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [receiverName]: '',
    };
    setValidation(newValidation);

    fetchRate({
      countryCode,
      currency,
      setRate: setCurrentRateValue,
      rateProp,
      currencyProp,
      information,
      setInformation,
      callback: Number(information[receiverName] || 0)
        ? null
        : () => setFetching(false),
    });

    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  const handleDropdownOnToggle = () => {
    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      $isDisabled={isDisabled}
      onClick={(e) => {
        e.preventDefault();

        if (isDisabled) return;

        if (isOnlyReceiverCurrency) return;

        if (isFetching) return;

        if (discountApplied && setOpenDiscountImportantPopup) {
          setOpenDiscountImportantPopup(true);
        }

        onClick(e);

        dispatch({
          type: SET_SHOW_BACKDROP,
          payload: {
            isShow: !backdrop?.isShow,
            current: ADD_TRANSACTION_CURRENCY_NAME + receiverName,
          },
        });
      }}
    >
      {children}
      {!isOnlyReceiverCurrency && !isDisabled && (
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          styles={{
            marginLeft: '14px',
            transform:
              backdrop?.current ===
                ADD_TRANSACTION_CURRENCY_NAME + receiverName &&
              backdrop?.isShow &&
              'rotate(180deg)',
          }}
        />
      )}
    </ButtonToggle>
  ));

  const remainingCurrencies = () => {
    if (!information['isAddAnotherReceivingCurrency']) {
      return receiveLimits;
    }

    return receiverName === ADD_TRANSACTION_RECEIVER_GET_NAME
      ? receiveLimits.filter(
          (r) =>
            r?.currencyCode !==
            information['currentCurrencySelectedAnother']?.currencyCode
        )
      : receiveLimits.filter(
          (r) =>
            r?.currencyCode !==
            information['currentCurrencySelected']?.currencyCode
        );
  };

  return (
    <CurrencyDropdownStyled>
      <Dropdown onToggle={handleDropdownOnToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          {currentCurrencySelected?.currencyCode}
        </Dropdown.Toggle>

        <DropdownMenu>
          {remainingCurrencies()?.map((currency, index) => (
            <DropdownItem
              key={currency.currencyCode}
              eventKey={index}
              onClick={() => handleCurrencySelected(currency)}
            >
              {currency.currencyCode}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </CurrencyDropdownStyled>
  );
};

const CurrencyDropdownStyled = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 27px;
  right: 10.5px;
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  height: fit-content;
  min-width: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 8px;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 40px) !important;
  background: var(--ds-c-white);
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  text-transform: capitalize;
  width: 64px;
  height: 40px;
  background: transparent;

  &:hover {
    border-radius: 8px;
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--ds-c-grey-dark);
  color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  text-decoration: none;
  text-transform: uppercase;
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  height: 44px;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-hover);
    color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};

    & svg {
      filter: var(--ds-f-c-grey-hover);
    }
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

export default CustomCurrencyDropdown;
