import { fetchTransactionInformation } from 'components/Transactions/func';
import { fetchOrderDetails } from 'components/fx/func';
import {
  EDIT_PROFILE_NEW_PASSWORD_FIELD,
  EDIT_PROFILE_NEW_PASSWORD_NAME,
  EDIT_PROFILE_REPASSWORD_FIELD,
  EDIT_PROFILE_REPASSWORD_NAME,
  FX_TRANSACTIONS_PREFIX,
  TRANSACTIONS_PREFIX,
} from 'constants';
import { ref, update } from 'firebase/database';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import {
  getPathNotificationsBySenderId,
  getRealtimeDB,
} from 'hooks/useFirebase';
import { dispatchStore } from 'store';
import {
  SET_NEW_NOTIFICATION,
  SET_SHOW_LOADING,
  SET_TRANSACTION_DETAILS,
} from 'store/action';
import { firebaseAppDefault } from 'store/firebase';
import { dispatchStore as dispatchFxStore } from 'store/fx';
import { SET_FX_TRANSACTION_DETAILS } from 'store/fx/action';

export const getAvatarName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;
  }

  if (firstName && !lastName) {
    return firstName.substring(0, 2);
  }

  if (!firstName && lastName) {
    return lastName.substring(0, 2);
  }
};

const handlePasswordValueValidation = (
  value,
  name,
  information,
  validation
) => {
  if (value && name === EDIT_PROFILE_NEW_PASSWORD_NAME) {
    const isValid = Boolean(
      EDIT_PROFILE_NEW_PASSWORD_FIELD.checkValidFn(value)
    );

    if (!isValid) {
      validation = {
        ...validation,
        [name]: EDIT_PROFILE_NEW_PASSWORD_FIELD.msgInvalid,
      };
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (information[EDIT_PROFILE_REPASSWORD_NAME]) {
      if (value === information[EDIT_PROFILE_REPASSWORD_NAME]) {
        validation = {
          ...validation,
          [EDIT_PROFILE_REPASSWORD_NAME]: '',
        };

        return validation;
      }

      if (value !== information[EDIT_PROFILE_REPASSWORD_NAME]) {
        validation = {
          ...validation,
          [EDIT_PROFILE_REPASSWORD_NAME]:
            EDIT_PROFILE_REPASSWORD_FIELD.msgNotMatch,
        };
      }
    }
  }

  return validation;
};

const handleRePasswordValueValidation = (
  value,
  name,
  information,
  validation
) => {
  if (value && name === EDIT_PROFILE_REPASSWORD_NAME) {
    const isValid = Boolean(EDIT_PROFILE_REPASSWORD_FIELD.checkValidFn(value));

    if (!isValid) {
      validation = {
        ...validation,
        [name]: EDIT_PROFILE_REPASSWORD_FIELD.msgInvalid,
      };

      return validation;
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (information[EDIT_PROFILE_NEW_PASSWORD_NAME]) {
      if (value !== information[EDIT_PROFILE_NEW_PASSWORD_NAME]) {
        validation = {
          ...validation,
          [name]: EDIT_PROFILE_REPASSWORD_FIELD.msgNotMatch,
        };
      }
    }
  }

  return validation;
};

const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  return validation;
};

export const checkFormInputValidation = (
  value,
  field,
  information,
  validation
) => {
  const { name, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handlePasswordValueValidation(
    value,
    name,
    information,
    validation
  );

  validation = handleRePasswordValueValidation(
    value,
    name,
    information,
    validation
  );

  return validation;
};

export const updateNotifyToReaded = (currentUser, notiData) => {
  const notiPayload = {
    ...notiData,
    read: true,
  };

  const updates = {};
  const path = getPathNotificationsBySenderId(
    currentUser?.senderId,
    `/${notiData?.uuid}`
  );
  updates[path] = notiPayload;

  try {
    update(ref(getRealtimeDB(firebaseAppDefault)), updates);
  } catch (error) {
    console.error(error?.message);
  }
};

export const updateNotificationsToReadAll = (notifications, currentUser) => {
  /* eslint-disable-next-line no-extra-boolean-cast */
  if (Boolean(notifications?.length)) {
    notifications.forEach((notiData) => {
      const notiPayload = {
        ...notiData,
        read: true,
      };

      const updates = {};
      const path = getPathNotificationsBySenderId(
        currentUser?.senderId,
        `/${notiData?.uuid}`
      );
      updates[path] = notiPayload;

      try {
        update(ref(getRealtimeDB(firebaseAppDefault)), updates);
      } catch (error) {
        console.error(error?.message);
      }
    });
  }

  dispatchStore({
    type: SET_NEW_NOTIFICATION,
    payload: false,
  });
};

export const updateNotificationsToClearAll = (notifications, currentUser) => {
  /* eslint-disable-next-line no-extra-boolean-cast */
  if (Boolean(notifications?.length)) {
    notifications.forEach((notiData) => {
      const notiPayload = {
        ...notiData,
        read: true,
        deleted: true,
      };

      const updates = {};
      const path = getPathNotificationsBySenderId(
        currentUser?.senderId,
        `/${notiData?.uuid}`
      );
      updates[path] = notiPayload;

      try {
        update(ref(getRealtimeDB(firebaseAppDefault)), updates);
      } catch (error) {
        console.error(error?.message);
      }
    });
  }

  dispatchStore({
    type: SET_NEW_NOTIFICATION,
    payload: false,
  });
};

export const redirectToTransactionDetails = async (
  token,
  notiData,
  navigate
) => {
  const { data } = notiData || {};
  const tranId = data?.refNumber || data?.tranId;

  if (!navigate) {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.location.href = `${homeUrl}${TRANSACTIONS_PREFIX}?tid=${tranId}}${
      data?.uuid ? `&mid=${data?.uuid}` : ''
    }`;

    return;
  }

  dispatchStore({ type: SET_SHOW_LOADING, payload: true });

  const transactionInformation = await fetchTransactionInformation(
    token,
    tranId
  );

  if (transactionInformation) {
    dispatchStore({
      type: SET_TRANSACTION_DETAILS,
      payload: transactionInformation,
    });

    Cookies.remove(MAKE_PAYMENT_KEY);

    if (navigate) {
      navigate(
        `${TRANSACTIONS_PREFIX}?tid=${tranId}${
          data?.uuid ? `&mid=${data?.uuid}` : ''
        }`
      );
    }
  }

  dispatchStore({ type: SET_SHOW_LOADING, payload: false });

  dispatchStore({
    type: SET_NEW_NOTIFICATION,
    payload: false,
  });
};

export const redirectToFxTransactionDetails = async (
  token,
  notiData,
  navigate
) => {
  const { data } = notiData || {};
  const tranId = data?.orderRef || data?.refNumber || data?.tranId;

  if (!navigate) {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.location.href = `${homeUrl}${FX_TRANSACTIONS_PREFIX}?tid=${tranId}}${
      data?.uuid ? `&mid=${data?.uuid}` : ''
    }`;

    return;
  }

  dispatchStore({ type: SET_SHOW_LOADING, payload: true });

  const transactionInformation = await fetchOrderDetails(token, tranId);

  if (transactionInformation) {
    dispatchFxStore({
      type: SET_FX_TRANSACTION_DETAILS,
      payload: transactionInformation,
    });

    Cookies.remove(FX_MAKE_PAYMENT_KEY);

    if (navigate) {
      navigate(
        `${FX_TRANSACTIONS_PREFIX}?tid=${tranId}${
          data?.uuid ? `&mid=${data?.uuid}` : ''
        }`
      );
    }
  }

  dispatchStore({ type: SET_SHOW_LOADING, payload: false });

  dispatchStore({
    type: SET_NEW_NOTIFICATION,
    payload: false,
  });
};
