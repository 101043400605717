import SearchIcon from 'assets/ver2/icons/search-icon.svg';
import {
  fetchTransactionsFiltered,
  getBodyPayloadFiltered
} from 'components/FXTransactions/func';
import {
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
  ENTER_KEY_DOWN,
  PAGE_DEFAULT,
} from 'constants';
import {
  FX_TRANSACTION_TABLE_SORT_ORDER_INITIAL
} from 'constants/fx';
import { refNumberPhoneValid } from 'helpers';
import useAuth from 'hooks/useAuth';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { arrayUtils } from 'utils';

const TRANSACTION_SEARCH_INPUT_FIELD = 'transaction-search-input-field';
const REF_NUMBER_LENGTH_MAX = 7;

const SearchTransaction = ({
  applyFiltered,
  transactions,
  setTransactions,
  searchValue,
  setSearchValue,
  setTotalPages,
  setCurrentPage,
  setSortOrder,
  setTotalElements,
  setFetching = () => {},
}) => {
  const { t } = useLang();
  const { token } = useAuth();

  const { debounce } = useDebounceCallback((value) => {
    if (!value) {
      // if (isEmpty(transactions)) return;

      const bodyPayload = getBodyPayloadFiltered(applyFiltered);

      setTransactions(null);
      fetchTransactionsFiltered({
        token,
        body: bodyPayload,
        page: PAGE_DEFAULT,
        setTransactions,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });
    }
  }, DEBOUNCE_SEC);

  const searchByRefNumber = async (value) => {
    if (
      value?.length <= REF_NUMBER_LENGTH_MAX
    ) {
      const valueValid = refNumberPhoneValid(value);

      if (valueValid) {
        let bodyPayload = getBodyPayloadFiltered(applyFiltered);

        bodyPayload = {
          ...bodyPayload,
          orderRef: valueValid,
        };

        setTransactions(null);
        fetchTransactionsFiltered({
          token,
          body: bodyPayload,
          page: PAGE_DEFAULT,
          setTransactions,
          setCurrentPage,
          setTotalPages,
          setTotalElements,
          setFetching,
        });
      }
    }
  };

  const handleSearchTransaction = () => {
    setFetching(true);
    searchByRefNumber(searchValue);
    setSortOrder(FX_TRANSACTION_TABLE_SORT_ORDER_INITIAL);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (value?.length <= REF_NUMBER_LENGTH_MAX) {
      setSearchValue(value);
      if (!value) {
        debounce(value);
      }
    }
  };

  const handleOnKeyDown = (e) => {
    const { key } = e;
    if (key === ENTER_KEY_DOWN) {
      handleSearchTransaction();
    }
  };

  return (
    <SearchTransactionStyled>
      <SearchInput
        name={TRANSACTION_SEARCH_INPUT_FIELD}
        type="text"
        value={searchValue || ''}
        placeholder={t('fx_search_reference')}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />
      <SearchIconStyled
        src={SearchIcon}
        width={24}
        height={24}
        alt=""
        onClick={handleSearchTransaction}
      />
    </SearchTransactionStyled>
  );
};

const SearchTransactionStyled = styled.div`
  position: relative;
  height: 44px;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: unset;
    width: 287px;
  }
`;

const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  border: 1px solid #b0b6bd;
  border-radius: 8px;
  background: var(--ds-c-white);
  width: 100%;
  height: 44px;
  padding: 10px;
  padding-right: 32px;
  float: left;

  &:focus,
  &:focus-visible {
    border: 1px solid #b0b6bd !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #b0b6bd;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    width: 287px;
  }
`;
const SearchIconStyled = styled.img`
  position: absolute;
  top: 10px;
  left: calc(100% - 32px);
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 254px;
  }
`;

export default SearchTransaction;
