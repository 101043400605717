import NavbarFooter from 'components/common/NavbarFooter/ver2';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import React from 'react';
import styled from 'styled-components';
import Offers from 'components/Offers/components/Offers';
import OffersMobile from 'components/Offers/components/Offers/mobile';

const OffersPage = () => {
  const { isMobile, isTablet } = useDetectDevice();

  if (isMobile || isTablet) {
    return (
      <OffersPageStyled>
        <OffersMobile />
        <NavbarFooter />
      </OffersPageStyled>
    );
  }
  return (
    <>
      <OffersPageStyled>
        <Offers />
      </OffersPageStyled>
    </>
  );
};

const OffersPageStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100vh - 70px);
  padding: 16px 24px;
  position: relative;
  // background-color: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 40px auto;
    padding: 24px;
    width: 486px;
  }
`;

export default OffersPage;
