import {
  fetchAllDiscounts,
  getDiscountsTypeOther,
  getDiscountsTypePromotion,
} from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import { OFFERS_TABS, PROFILE_PREFIX, PROMOTION } from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { domUtils } from 'utils';
import DiscountsTab from '../DiscountsTab';

const Offers = () => {
  const { t } = useLang();
  const { token } = useAuth();

  const [currentTab, setCurrentTab] = useState(0);
  const [promotion, setPromotion] = useState(null);
  const [voucher, setVoucher] = useState(null);

  const isFetching = useRef();

  useEffect(() => {
    if (token && !voucher && !promotion && !isFetching.current) {
      isFetching.current = true;

      fetchAllDiscounts(token, (data) => {
        setPromotion(getDiscountsTypePromotion(data));

        setVoucher(getDiscountsTypeOther(data));

        isFetching.current = false;
      });
    }
  }, [token]);

  const getNumOfItem = (items) =>
    items?.length > 0 ? ' (' + items?.length + ')' : '';

  const tabContentReference = {
    0: <DiscountsTab items={promotion} type={PROMOTION} />,
    1: <DiscountsTab items={voucher} />,
  };

  const handleTabOnClick = (index) => {
    setCurrentTab(index);

    domUtils.changeAddressBarUrlWithoutReloadPage({
      prefix: PROFILE_PREFIX,
    });
  };

  return (
    <>
      <OffersStyled>
        <Title>{t('label_offers')}</Title>

        <TabWrap>
          {OFFERS_TABS.map((tab, index) => (
            <ButtonCommon
              key={tab.label}
              value={
                t(tab.label) +
                (index === 0 ? getNumOfItem(promotion) : getNumOfItem(voucher))
              }
              background={
                currentTab === index ? 'var(--c-tab-active-2)' : 'transparent'
              }
              color={'var(--ds-c-blue)'}
              styles={{
                width: '50%',
                borderRadius: '0px',
                height: '36px',
                float: 'left',
                color: 'var(--ds-c-blue)',
                ...(currentTab !== index && {
                  fontWeight: 500,
                  borderBottom: '2px solid #FFFFFF',
                }),
                ...(currentTab === index && {
                  borderBottom: '2px solid var(--ds-c-blue)',
                }),
              }}
              onClick={() => handleTabOnClick(index)}
            />
          ))}
        </TabWrap>
        <ContentBody>{tabContentReference[currentTab]}</ContentBody>
      </OffersStyled>
    </>
  );
};

const OffersStyled = styled.div`
  display: inline-block;
  width: 438px;
  height: fit-content;
`;

const Title = styled.h4`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  margin-top: 42px;
  margin-bottom: 16px;
`;

const TabWrap = styled.div`
  height: 44px;
  background-color: transparent;
  color: var(--ds-c-blue);
  padding: 4px;
`;

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;
export default Offers;
