import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import { checkFormInputValidation as checkFormInputValidationOfAddress } from 'components/FormRegister/ResidentialAddress/func';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import AdditionalInformation from 'components/fx/FxFormAddTransaction/AdditionalInformation';
import { checkFormInputValidation as checkFormInputValidationOfContact } from 'components/fx/FxFormAddTransactionAsGuest/FormFxContactInformationModal/func';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import { CONFIG_OCCUPATION_EXTRAS, DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_GENERAL_DETAILS_NAME_FIELDS,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_DOB_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD,
  FX_ADD_TRANSACTION_OCCUPATION_NAME,
  FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
  FX_ADD_TRANSACTION_PURPOSE_FIELD,
  FX_ADD_TRANSACTION_PURPOSE_NAME,
  FX_ADD_TRANSACTION_SOURCE_FIELD,
  FX_ADD_TRANSACTION_SOURCE_NAME,
  RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import CalculatorForm from '../../FxFormAddTransaction/CalculatorForm';
import ContactInformation from './ContactInformation';
import PersonalInformation from './PersonalInformation';
import ResidentialAddress from './ResidentialAddress';

const initialFormField = RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION;

const ConfirmInformationAsGuest = ({
  handleOnToggleCancelPopup,
  handleOnViewPickupLocation,
}) => {
  const { t } = useLang();
  const { isDesktop } = useDetectDevice();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);

  const { getTitleBodySection, isMakePayment } = useFxAddTransaction();
  const {
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    confirmInformation,
    setConfirmInformation,
    isAdditionalInformationRequired,
  } = useFxFormAddTransactionStore();
  const { checkAgeToAllowUseService } = useFxAddTransaction();

  const isNextDisabled = false;

  useEffect(() => {
    setInformation(confirmInformation);
  }, []);

  const getTitleSection = () => {
    return t('fx_add_transaction_enter_information');
  };

  const handleBackStep = () => {
    let newInformation = {
      ...information,
    };
    let newValidation = {
      ...validation,
    };

    newValidation = checkAgeToAllowUseService({
      dateUTC: information[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME],
      validation: newValidation,
      field: FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_DOB_FIELD,
    });
    if (newValidation[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]) {
      newInformation = {
        ...newInformation,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]: '',
      };
    }

    setConfirmInformation(newInformation);
    setAddTransactionCurrentStep(currentStep - 1);
  };
  const handleNextStep = () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!information[FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME]) {
      delete newInformation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME
      ];
      delete newValidation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME
      ];
    }

    if (
      !information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]
    ) {
      delete newInformation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME
      ];
      delete newValidation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME
      ];
    }

    if (
      !information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]
    ) {
      delete newInformation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME
      ];
      delete newValidation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME
      ];
    }

    if (
      !information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]
    ) {
      delete newInformation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME
      ];
      delete newValidation[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME
      ];
    }

    newValidation = checkAgeToAllowUseService({
      dateUTC: information[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME],
      validation: newValidation,
      field: FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_DOB_FIELD,
    });

    delete newInformation?.isEnterAddressManuallySelected;
    delete newValidation?.isEnterAddressManuallySelected;

    if (!isAdditionalInformationRequired) {
      delete newInformation[FX_ADD_TRANSACTION_OCCUPATION_NAME];
      delete newValidation[FX_ADD_TRANSACTION_OCCUPATION_NAME];
      delete newInformation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      delete newValidation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      delete newInformation[FX_ADD_TRANSACTION_PURPOSE_NAME];
      delete newValidation[FX_ADD_TRANSACTION_PURPOSE_NAME];
      delete newInformation[FX_ADD_TRANSACTION_SOURCE_NAME];
      delete newValidation[FX_ADD_TRANSACTION_SOURCE_NAME];
    } else {
      if (
        !CONFIG_OCCUPATION_EXTRAS.find(
          (occupation) =>
            occupation === information[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key
        )
      ) {
        delete newInformation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
        delete newValidation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      }
    }

    const isAllowNext =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowNext) {
      setConfirmInformation(information);
      setAddTransactionCurrentStep(currentStep + 1);
    }

    if (!isAllowNext) {
      FX_ADD_TRANSACTION_ENTER_INFORMATION_GENERAL_DETAILS_NAME_FIELDS.forEach(
        (field) => {
          if (
            field.name === FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME
          ) {
            if (
              information[FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME]
            ) {
              newValidation = checkFormInputValidation(
                information[field.name],
                field,
                newValidation
              );
            }
          } else {
            newValidation = checkFormInputValidation(
              information[field.name],
              field,
              newValidation
            );
          }
        }
      );

      newValidation = checkFormInputValidation(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_DOB_FIELD,
        newValidation
      );
      if (information[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]) {
        newValidation = checkAgeToAllowUseService({
          dateUTC: information[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME],
          validation: newValidation,
          field:
            FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_DOB_FIELD,
        });
      }

      newValidation = checkFormInputValidation(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD,
        newValidation
      );

      newValidation = checkFormInputValidationOfContact(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_FIELD,
        information,
        newValidation
      );
      newValidation = checkFormInputValidationOfContact(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD,
        information,
        newValidation
      );

      newValidation = checkFormInputValidationOfAddress(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
        newValidation
      );
      if (
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]
      ) {
        newValidation = checkFormInputValidationOfAddress(
          information[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME
          ],
          FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[0],
          newValidation
        );
      }
      newValidation = checkFormInputValidationOfAddress(
        information[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME
        ],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[1],
        newValidation
      );
      newValidation = checkFormInputValidationOfAddress(
        information[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME
        ],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[2],
        newValidation
      );
      newValidation = checkFormInputValidationOfAddress(
        information[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME
        ]?.key,
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[3],
        newValidation
      );
      newValidation = checkFormInputValidationOfAddress(
        information[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME
        ],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[4],
        newValidation
      );
      newValidation = checkFormInputValidationOfAddress(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[5],
        newValidation
      );
      newValidation = checkFormInputValidationOfAddress(
        information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME],
        FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[6],
        newValidation
      );

      if (isAdditionalInformationRequired) {
        newValidation = checkFormInputValidation(
          information[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key,
          FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS[0],
          newValidation
        );
        if (
          CONFIG_OCCUPATION_EXTRAS.find(
            (occupation) =>
              occupation ===
              information[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key
          )
        ) {
          newValidation = checkFormInputValidation(
            information[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]?.key,
            FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS[1],
            newValidation
          );
        }
        newValidation = checkFormInputValidation(
          information[FX_ADD_TRANSACTION_PURPOSE_NAME]?.key,
          FX_ADD_TRANSACTION_PURPOSE_FIELD,
          newValidation
        );
        newValidation = checkFormInputValidation(
          information[FX_ADD_TRANSACTION_SOURCE_NAME]?.key,
          FX_ADD_TRANSACTION_SOURCE_FIELD,
          newValidation
        );
      }

      setValidation(newValidation);
    }
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    return (
      <CalculatorForm
        ref={null}
        isCalculatorFormAccordion={true}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    );
  };
  const renderActionsButton = () => {
    return (
      <NextWrap>
        {renderCalculatorFormMobile()}
        <ActionWrapper>
          <ButtonCommonWrap>
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: !isDesktop && '100%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              borderNameVariable={isDesktop && '--border-filter'}
              isFill={false}
            />
          </ButtonCommonWrap>
          <ButtonCommon
            value={t('button_confirm')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? '150px' : '50%',
            }}
            onClick={handleNextStep}
            isDisabled={isNextDisabled}
            isUseKeyDown={!isNextDisabled}
          />
        </ActionWrapper>
      </NextWrap>
    );
  };

  const renderNoticeSection = () => {
    if (isMakePayment) return null;
    return (
      <NoticeSection>
        <img src={NoticeIcon} width={50} height={41} />
        <p>
          {t('fx_add_transaction_confirm_information_as_guest_notice_desc')}
        </p>
      </NoticeSection>
    );
  };

  const renderAdditionalInformation = () => {
    if (!isAdditionalInformationRequired) return null;
    return (
      <AdditionalInformation
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    );
  };

  return (
    <>
      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={getTitleSection()}
        CalculatorComponent={isDesktop ? <CalculatorForm /> : null}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        onViewPickupLocation={handleOnViewPickupLocation}
      />
      <ConfirmInformationAsGuestStyled>
        {renderNoticeSection()}
        <PersonalInformation
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
        <ContactInformation
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
        <ResidentialAddress
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
        {renderAdditionalInformation()}
        {renderActionsButton()}
      </ConfirmInformationAsGuestStyled>
    </>
  );
};

const ConfirmInformationAsGuestStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 190px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  margin-inline: -16px;
  padding-inline: 0px;
  background: transparent;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: fit-content;
    border-top: unset;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid var(--ds-bg-2);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
const ButtonCommonWrap = styled.div`
  width: 50%;
`;

const NoticeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: var(--ds-c-yellow-light);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;

  & p {
    ${paragraphMixin};
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 666px;
  }
`;

export default ConfirmInformationAsGuest;
