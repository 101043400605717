import { api } from 'api';
import { dispatchStore } from 'store';
import { SET_EVENT_AVAILABLE } from 'store/action';
import { arrayUtils } from 'utils';

export const handleGetEventsAvailable = async (token) => {
  const { data } = await api.getEventsAvailable(token);

  if (data && !arrayUtils.isArrayEmpty(data)) {
    dispatchStore({ type: SET_EVENT_AVAILABLE, payload: data[0] });
  }
};
