import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME,
  PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME,
  PAYMENT_BY_DEPOSIT_EXPIRY_FIELD,
  PAYMENT_BY_DEPOSIT_EXPIRY_NAME,
  PAYMENT_BY_DEPOSIT_NAME,
  PAYMENT_BY_DEPOSIT_NAME_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_NAME,
  RESET_PAYMENT_BY_DEPOSIT_STORE,
} from 'constants';
import { format, masterCardNumberValid, visaNumberValid } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { numberUtils } from 'utils';
import FormInputCardExpiryDateValidation from '../../FormInputCardExpiryDateValidation';
import FormInputPaymentCardNumberValidation from '../../FormInputPaymentCardNumberValidation';
import FormInputValidation from '../../FormInputValidation';

const FormPaymentByDepositMobile = ({
  isOpen,
  transactionInformation,
  cardInformation,
  setCardInformation,
  cardValidation,
  setCardValidation,
  onClose,
  onClick,
}) => {
  const { t } = useLang();

  const { token } = useAuth();
  const { dispatch } = useStore();

  useEffect(() => {
    return () => {
      setCardInformation(RESET_PAYMENT_BY_DEPOSIT_STORE);
      setCardValidation(RESET_PAYMENT_BY_DEPOSIT_STORE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const nameOnCard = cardInformation[PAYMENT_BY_DEPOSIT_NAME]?.trim();

  const isCardTypeOther =
    !masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]) &&
    !visaNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]);

  const isCardCVCCVVNumberLengthValid = masterCardNumberValid(
    cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]
  )
    ? cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]?.length === 3
    : cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]?.length === 3;

  const isCardNumberLengthValid =
    cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]?.length === 16;

  const isPayDisabled =
    !cardInformation[PAYMENT_BY_DEPOSIT_NAME] ||
    cardValidation[PAYMENT_BY_DEPOSIT_NAME] ||
    !cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME] ||
    cardValidation[PAYMENT_BY_DEPOSIT_NUMBER_NAME] ||
    !cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME] ||
    cardValidation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME] ||
    (masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
      ? !cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME] ||
        cardValidation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]
      : !cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME] ||
        cardValidation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]) ||
    !isCardNumberLengthValid ||
    !isCardCVCCVVNumberLengthValid ||
    isCardTypeOther;

  const { totalPay } = transactionInformation;

  const parseAmountValue = (value) =>
    format.toAmountFloatStr(numberUtils.mathRound(value));

  const handlePay = async () => {
    const newCardInformation = { ...cardInformation };
    const newCardValidation = { ...cardValidation };

    if (visaNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])) {
      delete newCardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME];
      delete newCardValidation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME];
    }

    if (
      masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
    ) {
      delete newCardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME];
      delete newCardValidation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME];
    }

    const isAllowSubmit =
      Object.values(newCardInformation).every((value) => value) &&
      Object.values(newCardValidation).every((value) => !value) &&
      !isPayDisabled;

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await api.payMint(
          token,
          transactionInformation.refNumber,
          cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME],
          cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[0],
          cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[1],
          masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
            ? cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]
            : cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME],
          nameOnCard,
          false
        );

        if (data) {
          const { success, redirect3dsUrl } = data;

          if (success) {
            onClick(redirect3dsUrl);

            return;
          }

          onClick();

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        onClick();

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    } else {
      let newCardValidation = { ...cardValidation };

      if (isCardTypeOther) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_NUMBER_FIELD.msgNotSupport,
        };

        setCardValidation(newCardValidation);

        return;
      }

      if (!isCardNumberLengthValid) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_NUMBER_FIELD.msgInvalid,
        };

        setCardValidation(newCardValidation);

        return;
      }

      if (
        masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
      ) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD.msgInvalid,
        };
      }

      if (visaNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD.msgInvalid,
        };
      }

      setCardValidation(newCardValidation);
    }
  };

  const renderActionsButton = () => {
    return (
      <>
        <ButtonCommon
          value={t('button_cancel')}
          onClick={onClose}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--c-primary)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_pay')}
          onClick={handlePay}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isPayDisabled}
        />
      </>
    );
  };

  return (
    <>
      <ModalStyled show={isOpen} onHide={() => {}} animation={false}>
        <ModalHeader>{t('popup_payment_via_card')}</ModalHeader>
        <ModalBody>
          <TotalPay>{`${parseAmountValue(totalPay)} AUD`}</TotalPay>
          <FormInputValidation
            field={PAYMENT_BY_DEPOSIT_NAME_FIELD}
            information={cardInformation}
            setInformation={setCardInformation}
            validation={cardValidation}
            setValidation={setCardValidation}
          />
          <FormInputPaymentCardNumberValidation
            field={PAYMENT_BY_DEPOSIT_NUMBER_FIELD}
            information={cardInformation}
            setInformation={setCardInformation}
            validation={cardValidation}
            setValidation={setCardValidation}
          />
          <FormInputCardExpiryDateValidation
            field={PAYMENT_BY_DEPOSIT_EXPIRY_FIELD}
            information={cardInformation}
            setInformation={setCardInformation}
            validation={cardValidation}
            setValidation={setCardValidation}
          />
          <>
            {masterCardNumberValid(
              cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]
            ) ? (
              <FormInputValidation
                field={PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD}
                information={cardInformation}
                setInformation={setCardInformation}
                validation={cardValidation}
                setValidation={setCardValidation}
              />
            ) : (
              <FormInputValidation
                field={PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD}
                information={cardInformation}
                setInformation={setCardInformation}
                validation={cardValidation}
                setValidation={setCardValidation}
              />
            )}
          </>
        </ModalBody>
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  background-color: transparent;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & .modal-dialog {
    position: absolute;
    margin: 0;
    height: fit-content;
    width: 100%;
    max-width: 100%;
    transform: none;
  }

  & .modal-content {
    padding: 24px 24px 24px 24px;
    border-radius: 0px;
    box-shadow: none;
    background: var(--ds-c-white);
    width: 100%;
    height: fit-content;
    margin-inline: auto;
    border-radius: 12px 12px 0px 0px;
    position: fixed;
    bottom: 0px;
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  padding: 0;
  margin-bottom: 0;
  display: block;
  margin-bottom: 24px;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 0px;
`;
const TotalPay = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #1c3f88;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef2f5;
  height: 44px;
  width: 100%;
  border-radius: 12px;
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
`;
const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: end;
`;

export default FormPaymentByDepositMobile;
