import { api } from 'api';
import KeyIcon from 'assets/icons/key-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { HHMT_BRAND, REMOX_BRAND } from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { stringUtils } from 'utils';
import FormInputSwitch from '../FormInputSwitch';

import FormChangePasswordPopup from '../FormChangePasswordPopup';
import useStore from 'hooks/useStore';

const Security = () => {
  const { t } = useLang();
  const { token } = useAuth();
  const { isHHMTTheme } = useTheme();
  const { state } = useStore();
  const { currentUser } = state;

  const [isEmailChecked, setEmailChecked] = useState(false);
  const [isSMSChecked, setSMSChecked] = useState(false);
  const [isShowFormResetPasswordPopup, setShowFormResetPasswordPopup] =
    useState(false);

  const fetchProfileOptins = async () => {
    try {
      const { data } = await api.getOptin(token);

      if (data) {
        const { optInByEmail, optInByText } = data;

        setEmailChecked(optInByEmail);

        setSMSChecked(optInByText);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchProfileOptins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleOptin = async (type, value, setValue) => {
    try {
      const { data } = await api.saveOptin(token, type, value);

      if (data) {
        setValue(value);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleOnToggleFormResetPasswordPopup = () => {
    setShowFormResetPasswordPopup(!isShowFormResetPasswordPopup);
  };

  return (
    <>
      {isShowFormResetPasswordPopup && (
        <FormChangePasswordPopup
          isOpen={isShowFormResetPasswordPopup}
          onClose={handleOnToggleFormResetPasswordPopup}
        />
      )}

      <SecurityStyled>
        <Title>{t('label_security')}</Title>
        <SubTitle>{t('form_password')}</SubTitle>
        <ButtonCommon
          value={t('button_change_password')}
          color="var(--c-tertiary)"
          background="var(--ds-c-white)"
          isFill={false}
          styles={{
            paddingInline: 0,
          }}
          borderNameVariable="--border-secondary"
          iconSrc={KeyIcon}
          iconStyles={{
            filter: isHHMTTheme
              ? 'var(--ds-f-c-yellow)'
              : 'var(--ds-f-c-blue-dark)',
          }}
          onClick={handleOnToggleFormResetPasswordPopup}
        />
        <Divider />
        <SubTitle>{t('label_communication_preferences')}</SubTitle>
        <Desc>
          {stringUtils.replaceKeyword(
            t('label_communication_preferences_desc'),
            [
              {
                key: 'brand',
                value: isHHMTTheme ? HHMT_BRAND : REMOX_BRAND,
              },
            ]
          )}
        </Desc>
        <Button
          onClick={() => handleOptin('EMAIL', !isEmailChecked, setEmailChecked)}
        >
          Email <FormInputSwitch isChecked={isEmailChecked} />
        </Button>
        <Button
          onClick={() => handleOptin('SMS', !isSMSChecked, setSMSChecked)}
        >
          SMS <FormInputSwitch isChecked={isSMSChecked} />
        </Button>
      </SecurityStyled>
    </>
  );
};

const SecurityStyled = styled.div`
  display: inline-block;
  width: 438px;
  height: fit-content;
`;

const Title = styled.h4`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  margin-top: 42px;
  margin-bottom: 16px;
`;

const SubTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e1012;

  display: flex;
  align-items: center;
`;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Button = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 24px;
`;

export default Security;
