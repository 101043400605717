/* eslint-disable no-unused-vars */
import LocationHHMTIcon from 'assets/icons/location-hhmt-icon.svg';
import LocationRemoxIcon from 'assets/icons/location-remox-icon.svg';
import MobileHHMTIcon from 'assets/icons/mobile-hhmt-icon.svg';
import MobileRemoxIcon from 'assets/icons/mobile-remox-icon.svg';
import PhoneHHMTIcon from 'assets/icons/phone-hhmt-icon.svg';
import PhoneRemoxIcon from 'assets/icons/phone-remox-icon.svg';
import StarMarkedIcon from 'assets/icons/star-marked-icon.svg';
import TimerFillIcon from 'assets/ver2/icons/timer-small-fill-icon.svg';
import TimerHHMTIcon from 'assets/ver2/icons/timer-small-hhmt-icon.svg';
import TimerRemoxIcon from 'assets/ver2/icons/timer-small-remox-icon.svg';
import { fetchAllReceivers } from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  checkFormInputValidation,
  findCountryWithCountryName,
} from 'components/common/func';
import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_HOME_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_FIELD,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_FIELD,
  ADD_TRANSACTION_RECEIVER_NAME,
  ADD_TRANSACTION_STEP_REFERENCE,
  METHOD_BANK,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PICKUP,
  RECEIVERS_EDIT_SUFIX,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { formatter } from 'helpers';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
  SET_SHOW_LOADING,
} from 'store/action';
import styled from 'styled-components';
import { arrayUtils } from 'utils';

import { renderDiscountImportantPopup } from 'components/Discount/func';
import {
  checkBusinessReceiverDoNotSupportSendMoneyOnline,
  checkReceiverDoNotSupportSendMoneyOnline,
  getPayoutItem,
} from 'components/FormAddTransaction/func';
import { getNumberUnmaskStr } from 'components/FormEditReceiver/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useScrollIntoView from 'hooks/useScrollIntoView';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import { useRef } from 'react';
import BankPayoutInformation from '../../BankPayoutInformation/ver2';
import CustomReceiversDropdown from '../../CustomReceiversDropdown';
import EwalletPayoutInformation from '../../EwalletPayoutInformation/ver2';
import HomePayoutInformation from '../../HomePayoutInformation/ver2';
import PayoutMethodsGrid from '../../PayoutMethodsGrid/ver2';
import PickupPayoutInformation from '../../PickupPayoutInformation/ver2';

import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import {
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  AVATAR_OF_RECEIVER_TYPE_REFERENCE,
} from 'constants';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import { SERVICE_ALERTS_TYPE } from 'hooks/service-alerts/model/ServiceAlertsModel';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsModal from 'hooks/service-alerts/useServiceAlertsModal';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useServiceAlertsUtil from 'hooks/service-alerts/useServiceAlertsUtil';
import usePortalModal from 'hooks/usePortalModal';
import useValidationToCreateATransactionOnHoliday from 'hooks/useValidationToCreateATransactionOnHoliday';
import { isEmpty } from 'lodash';
import { Fade } from 'react-bootstrap';
import BodySectionHeader from '../../BodySectionHeader';
import MissingInformationPopup from '../../MissingInformationPopup';
import TableEstimatedPopup from '../../TableEstimatedPopup';
import TableEstimatedPopupMobile from '../../TableEstimatedPopup/mobile';

const initialFormField =
  RESET_ADD_TRANSACTION_INFORMATION_STORE.receiverPayoutMethod;

const ReceiverPayoutMethod = ({
  countryConfig,
  handleOnToggleCancelPopup,
  isDisabled,
  rid,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const {
    addTransactionInformation,
    currentReceiver,
    addTransactionCurrentStep: currentStep,
  } = state;

  const { token } = useAuth();
  const navigate = useNavigate();
  const { isHHMTTheme } = useTheme();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [receivers, setReceivers] = useState(null);
  const [isShowMissingInformationPopup, setShowMissingInformationPopup] =
    useState(false);
  const [isOpenTableEstimatedPopup, setOpenTableEstimatedPopup] =
    useState(false);
  const [currentReceiverSelected, setCurrentReceiverSelected] = useState(null);
  const [isOpenDiscountImportantPopup, setOpenDiscountImportantPopup] =
    useState(false);
  const [informationChangeBackup, setInformationChangeBackup] = useState(null);

  const { showPortalModalName } = usePortalModal();

  const { currentPayoutItemSelectedIndex } = information;
  const payoutMethod = information[ADD_TRANSACTION_PAYOUT_METHOD_NAME];
  const receiver = information[ADD_TRANSACTION_RECEIVER_NAME];

  const { serviceAlertsByCountryCode: serviceAlertsStore } =
    useServiceAlertsStore();
  const { serviceAlertsByPayoutMethod } = serviceAlertsStore || {};
  const { closeServiceAlertsModal } = useServiceAlertsModal();
  const { checkByCountryCode, checkByPayoutMethod } = useCheckServiceAlerts();
  let { isDisplayReceiverDetailsSection } = checkByCountryCode;
  const {
    checkServiceAlertsOfPayoutMethodItemDelay,
    checkServiceAlertsOfPayoutMethodItemDisable,
    checkReceiverMissingInformation,
  } = checkByPayoutMethod;
  const { isPreventNextStep1 } = useServiceAlertsUtil();
  const {
    isMakePayment,
    resetAddTransactionInformation,
    setCurrentReceiver,
    resetCurrentReceiver,
  } = useFormAddTransactionStore();
  const { resetServiceAlertsByCurrencies } = useServiceAlertsStore();

  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();
  const isReceiverDoNotSupportSendMoneyOnline =
    checkReceiverDoNotSupportSendMoneyOnline({
      receiver: currentReceiverSelected,
      countryConfig,
    });
  const isBusinessReceiverDoNotSupportSendMoneyOnline =
    checkBusinessReceiverDoNotSupportSendMoneyOnline({
      t,
      receiver: currentReceiverSelected,
      countryConfig,
    });

  isDisplayReceiverDetailsSection =
    isDisplayReceiverDetailsSection &&
    !isEmpty(currentReceiver) &&
    !isEmpty(receiver) &&
    !isEmpty(currentReceiverSelected) &&
    !isReceiverDoNotSupportSendMoneyOnline &&
    !isBusinessReceiverDoNotSupportSendMoneyOnline;

  const isNextDisabled = false;

  const configGlobal = useGetConfig({
    countryConfig,
  });
  const { mobile: mobileGlobal, phone: phoneGlobal } = configGlobal;

  const config = useGetConfig({
    countryConfig,
    dmCode: information[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
  });
  const { mobile, phone } = config;

  const mobileNumber = currentReceiver?.mobileNumber;
  const mobileFormat = formatter.formatParse(mobile?.format);
  const mobileFormatGlobal = formatter.formatParse(mobileGlobal?.format);
  const mobileNumberMask = mobileFormat
    ? formatter.mask(
        getNumberUnmaskStr(mobileNumber, mobileFormat),
        mobileFormat
      )
    : formatter.mask(
        getNumberUnmaskStr(mobileNumber, mobileFormatGlobal),
        mobileFormatGlobal
      );

  const phoneNumber = currentReceiver?.phoneNumber;
  const phoneFormat = formatter.formatParse(phone?.format);
  const phoneFormatGlobal = formatter.formatParse(phoneGlobal?.format);
  const phoneNumberMask = phoneFormat
    ? formatter.mask(getNumberUnmaskStr(phoneNumber, phoneFormat), phoneFormat)
    : formatter.mask(
        getNumberUnmaskStr(phoneNumber, phoneFormatGlobal),
        phoneFormatGlobal
      );

  const {
    isOpenHolidayUrgentPopup,
    setOpenHolidayUrgentPopup,
    HolidayUrgentPopup,
  } = useValidationToCreateATransactionOnHoliday({
    isDisabled,
    countryCode: countryConfig?.countryCode,
    payoutMethod,
    payoutItem: getPayoutItem(information, payoutMethod),
  });

  const validRef = useRef(null);
  useScrollIntoViewDelay(
    validRef,
    (currentReceiver || currentReceiverSelected) && validRef,
    [currentReceiver, currentReceiverSelected, validRef]
  );

  const validRef0 = useRef(null);
  useScrollIntoView(validRef0, validation[ADD_TRANSACTION_PAYOUT_METHOD_NAME], [
    validation,
  ]);

  const validRef1 = useRef(null);
  useScrollIntoView(validRef1, validation[ADD_TRANSACTION_METHOD_HOME_NAME], [
    validation,
  ]);
  useScrollIntoView(validRef1, payoutMethod === METHOD_HOME, [payoutMethod]);

  const validRef2 = useRef(null);
  useScrollIntoView(validRef2, validation[ADD_TRANSACTION_METHOD_PICKUP_NAME], [
    validation,
  ]);
  useScrollIntoView(validRef2, payoutMethod === METHOD_PICKUP, [payoutMethod]);

  const validRef3 = useRef(null);
  useScrollIntoView(validRef3, validation[ADD_TRANSACTION_METHOD_BANK_NAME], [
    validation,
  ]);
  useScrollIntoView(validRef3, payoutMethod === METHOD_BANK, [payoutMethod]);

  const validRef4 = useRef(null);
  useScrollIntoView(
    validRef4,
    validation[ADD_TRANSACTION_METHOD_EWALLET_NAME],
    [validation]
  );
  useScrollIntoView(validRef4, payoutMethod === METHOD_EWALLET, [payoutMethod]);

  useEffect(() => {
    if (isEmpty(countryConfig)) return;

    const currentCountrySelected =
      findCountryWithCountryName(currentReceiverSelected?.countryName) || {};
    const isCheckReceiverDoNotSupportSendMoneyOnline =
      !isMakePayment &&
      isReceiverDoNotSupportSendMoneyOnline &&
      !isEmpty(currentReceiverSelected) &&
      !isEmpty(currentCountrySelected) &&
      currentCountrySelected?.value === countryConfig?.countryCode;
    if (isCheckReceiverDoNotSupportSendMoneyOnline) {
      const message = t('popup_no_available_payout_method_warning');
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.SEND_MONEY_RECEIVER_MODAL,
        title: '',
        content: message,
        action: () => {
          setInformation(initialFormField);
          setCurrentReceiverSelected(null);
          resetCurrentReceiver();
          resetAddTransactionInformation();
        },
      });
      return;
    }

    const isCheckBusinessReceiverDoNotSupportSendMoneyOnline =
      !isMakePayment &&
      isBusinessReceiverDoNotSupportSendMoneyOnline &&
      !isEmpty(currentReceiverSelected) &&
      !isEmpty(currentCountrySelected) &&
      currentCountrySelected?.value === countryConfig?.countryCode;
    if (isCheckBusinessReceiverDoNotSupportSendMoneyOnline) {
      const message = t(
        'popup_no_available_payout_method_for_business_receiver_warning'
      );
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.SEND_MONEY_RECEIVER_MODAL,
        title: '',
        content: message,
        action: () => {
          setInformation(initialFormField);
          setCurrentReceiverSelected(null);
          resetCurrentReceiver();
          resetAddTransactionInformation();
        },
      });
      return;
    }

    const informationStore = addTransactionInformation.receiverPayoutMethod;

    if (!isEmpty(currentReceiver)) {
      const getReceiverContact = () => {
        if (!currentReceiver.mobile && !currentReceiver.phone) {
          return [null, null];
        }

        if (currentReceiver.mobile && currentReceiver.phone) {
          return [
            {
              ...currentReceiver.mobile,
              type: 'MOBILE',
            },
            {
              ...currentReceiver.phone,
              type: 'PHONE',
            },
          ];
        }

        if (currentReceiver.mobile && !currentReceiver.phone) {
          return [
            {
              ...currentReceiver.mobile,
              type: 'MOBILE',
            },
            null,
          ];
        }

        if (!currentReceiver.mobile && currentReceiver.phone) {
          return [
            null,
            {
              ...currentReceiver.phone,
              type: 'PHONE',
            },
          ];
        }
      };

      const newInformation = {
        [ADD_TRANSACTION_RECEIVER_NAME]: {
          ...currentReceiver,
          fullname: currentReceiver.lastName
            ? `${currentReceiver.firstName} ${currentReceiver.lastName}`
            : `${currentReceiver.firstName}`,
          countryId: currentReceiver.country.id,
          contact: getReceiverContact(),
        },
        [ADD_TRANSACTION_METHOD_BANK_NAME]:
          informationStore[ADD_TRANSACTION_METHOD_BANK_NAME],
        [ADD_TRANSACTION_METHOD_EWALLET_NAME]:
          informationStore[ADD_TRANSACTION_METHOD_EWALLET_NAME],
        [ADD_TRANSACTION_METHOD_PICKUP_NAME]:
          informationStore[ADD_TRANSACTION_METHOD_PICKUP_NAME],
        [ADD_TRANSACTION_METHOD_HOME_NAME]:
          informationStore[ADD_TRANSACTION_METHOD_HOME_NAME],
        [ADD_TRANSACTION_PAYOUT_METHOD_NAME]:
          informationStore[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
        currentPayoutItemSelectedIndex:
          informationStore.currentPayoutItemSelectedIndex,
      };
      setInformation(newInformation);

      return;
    }

    setInformation(informationStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMakePayment,
    countryConfig,
    currentReceiver,
    currentReceiverSelected,
    addTransactionInformation,
    isReceiverOfBusinessType,
    isReceiverOfIndividualType,
    isReceiverDoNotSupportSendMoneyOnline,
    isBusinessReceiverDoNotSupportSendMoneyOnline,
  ]);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_LOADING,
      payload: true,
    });
    fetchAllReceivers({
      token,
      setReceivers,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isReceiverMissingInformation = checkReceiverMissingInformation({
      config,
      receiver: currentReceiver,
      payoutMethod,
    });
    if (isReceiverMissingInformation) {
      const { type } = serviceAlertsByPayoutMethod || {};
      if (type === SERVICE_ALERTS_TYPE.DISABLE) {
        setShowMissingInformationPopup(false);
        return;
      }

      if (
        checkServiceAlertsOfPayoutMethodItemDisable({
          serviceAlertsStore,
          payoutMethod,
        })
      ) {
        setShowMissingInformationPopup(false);
        return;
      }

      if (
        checkServiceAlertsOfPayoutMethodItemDelay({
          serviceAlertsStore,
          payoutMethod,
        })
      ) {
        closeServiceAlertsModal();
        setShowMissingInformationPopup(true);
        return;
      }

      closeServiceAlertsModal();
      setShowMissingInformationPopup(true);
    }
  }, [
    config,
    currentReceiver,
    payoutMethod,
    serviceAlertsStore,
    serviceAlertsByPayoutMethod,
  ]);

  const handleOnChangeReceiver = () => {
    setInformation(initialFormField);
    setValidation(initialFormField);
    setShowMissingInformationPopup(false);
    setCurrentReceiverSelected(null);
    setCurrentReceiver(null);
    resetAddTransactionInformation();
    closeServiceAlertsModal();

    if (rid) {
      navigate(`${TRANSACTION_ADD_PREFIX}`);
    }
  };

  const handleOnUpdateReceiver = () => {
    closeServiceAlertsModal();
    navigate(
      `${RECEIVERS_EDIT_SUFIX}/${receiver?.receiverId}?dmCode=${payoutMethod}`
    );
  };

  const handleOnToggleTableEstimatedPopup = () => {
    setOpenTableEstimatedPopup(!isOpenTableEstimatedPopup);
  };

  const handleOnClickDiscountImportantPopup = () => {
    if (informationChangeBackup) {
      setInformation(informationChangeBackup);

      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...addTransactionInformation,
          receiverPayoutMethod: {
            ...addTransactionInformation.receiverPayoutMethod,
            ...informationChangeBackup,
          },
          remittance: {
            ...addTransactionInformation.remittance,
            [ADD_TRANSACTION_YOU_SEND_NAME]: 0,
            [ADD_TRANSACTION_RECEIVER_GET_NAME]: 0,
            [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
            [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
            isAddAnotherReceivingCurrency: false,
            currentCurrencySelectedAnother: null,
            currentRateAnotherStore: null,
            feeAmount: 0,
          },
          paymentMethod: {
            ...addTransactionInformation.paymentMethod,
            discountsAvailable: [],
            discountApplied: null,
          },
        },
      });

      setInformationChangeBackup(null);

      setValidation(initialFormField);

      resetServiceAlertsByCurrencies();
    }
  };

  const handleOnCloseDiscountImportantPopup = () => {
    setInformationChangeBackup(null);
  };

  const getPayoutInformationSectionVariant = () => ({
    [METHOD_HOME]: (
      <PayoutInformation>
        <PayoutDesc>
          <img src={isHHMTTheme ? TimerHHMTIcon : TimerRemoxIcon} alt="" />{' '}
          {t('add_transaction_home_desc_1')}
        </PayoutDesc>
        <PayoutDesc
          ref={validRef1}
          style={{
            color:
              validation[ADD_TRANSACTION_METHOD_HOME_NAME] && 'var(--ds-c-red)',
          }}
        >
          {t('add_transaction_home_desc_2')}:
        </PayoutDesc>
        <HomePayoutInformation
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          isDisabled={isDisabled}
        />
      </PayoutInformation>
    ),
    [METHOD_PICKUP]: (
      <PayoutInformation>
        <PayoutDesc
          style={{
            display:
              countryConfig?.countryCode === 'VNM' &&
              currentPayoutItemSelectedIndex === -1 &&
              'none',
          }}
        >
          <img src={isHHMTTheme ? TimerHHMTIcon : TimerRemoxIcon} alt="" />{' '}
          <span
            dangerouslySetInnerHTML={{
              __html:
                countryConfig?.countryCode === 'VNM'
                  ? currentPayoutItemSelectedIndex === 1
                    ? t('add_transaction_pickup_desc_vn_hcm')
                    : t('add_transaction_pickup_desc_vn_outside_hcm')
                  : t('add_transaction_pickup_desc_1'),
            }}
          />
        </PayoutDesc>
        <PayoutDesc
          ref={validRef2}
          style={{
            color:
              validation[ADD_TRANSACTION_METHOD_PICKUP_NAME] &&
              'var(--ds-c-red)',
          }}
        >
          {t('add_transaction_pickup_desc_2')}:
        </PayoutDesc>
        <PickupPayoutInformation
          countryConfig={countryConfig}
          currentReceiver={currentReceiver}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          currentStep={currentStep}
          isDisabled={isDisabled}
        />
      </PayoutInformation>
    ),
    [METHOD_BANK]: (
      <PayoutInformation>
        {countryConfig?.countryCode === 'VNM' ? (
          <PayoutDesc
            className="bank"
            onClick={handleOnToggleTableEstimatedPopup}
          >
            <img src={isHHMTTheme ? TimerFillIcon : TimerFillIcon} alt="" />{' '}
            {t('add_transaction_bank_desc_1')}
          </PayoutDesc>
        ) : (
          <PayoutDesc>
            <img src={isHHMTTheme ? TimerHHMTIcon : TimerRemoxIcon} alt="" />{' '}
            {t('add_transaction_bank_desc_4')}
          </PayoutDesc>
        )}

        <PayoutDesc>{t('add_transaction_bank_desc_2')}</PayoutDesc>
        <PayoutDesc
          ref={validRef3}
          style={{
            color:
              !arrayUtils.isArrayEmpty(currentReceiver?.banks) &&
              validation[ADD_TRANSACTION_METHOD_BANK_NAME] &&
              'var(--ds-c-red)',
          }}
        >
          {t('add_transaction_bank_desc_3')}:
        </PayoutDesc>
        <BankPayoutInformation
          countryConfig={countryConfig}
          currentReceiver={currentReceiver}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          currentStep={currentStep}
          isDisabled={isDisabled}
        />
      </PayoutInformation>
    ),
    [METHOD_EWALLET]: (
      <PayoutInformation>
        <PayoutDesc
          ref={validRef4}
          style={{
            color:
              !arrayUtils.isArrayEmpty(currentReceiver?.ewallets) &&
              validation[ADD_TRANSACTION_METHOD_EWALLET_NAME] &&
              'var(--ds-c-red)',
          }}
        >
          {t('add_transaction_ewallet_desc_1')}:
        </PayoutDesc>
        <EwalletPayoutInformation
          countryConfig={countryConfig}
          currentReceiver={currentReceiver}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          currentStep={currentStep}
          isDisabled={isDisabled}
        />
      </PayoutInformation>
    ),
  });
  const renderPayoutInformationSection = () => {
    if (
      checkServiceAlertsOfPayoutMethodItemDisable({
        serviceAlertsStore,
        payoutMethod,
      })
    )
      return null;

    return getPayoutInformationSectionVariant()?.[
      information[ADD_TRANSACTION_PAYOUT_METHOD_NAME]
    ];
  };

  const checkMethodValidationVariant = () => ({
    [METHOD_HOME]: (information, validation) => {
      return {
        ...validation,
        [ADD_TRANSACTION_METHOD_HOME_NAME]:
          !information[ADD_TRANSACTION_METHOD_HOME_NAME] &&
          t('form_choose_payout_method_invalid_2'),
      };
    },
    [METHOD_PICKUP]: (information, validation) => {
      return {
        ...validation,
        [ADD_TRANSACTION_METHOD_PICKUP_NAME]:
          !information[ADD_TRANSACTION_METHOD_PICKUP_NAME] &&
          t('form_choose_payout_method_invalid_2'),
      };
    },
    [METHOD_BANK]: (information, validation) => {
      return {
        ...validation,
        [ADD_TRANSACTION_METHOD_BANK_NAME]: arrayUtils.isArrayEmpty[
          information.banks
        ]
          ? 'label_no_bank_account'
          : !information[ADD_TRANSACTION_METHOD_BANK_NAME] &&
            'form_choose_payout_method_invalid_3',
      };
    },
    [METHOD_EWALLET]: (information, validation) => {
      return {
        ...validation,
        [ADD_TRANSACTION_METHOD_EWALLET_NAME]: arrayUtils.isArrayEmpty[
          information.ewallets
        ]
          ? 'label_no_ewallet'
          : !information[ADD_TRANSACTION_METHOD_EWALLET_NAME] &&
            'form_choose_payout_method_invalid_4',
      };
    },
  });

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    delete newInformation['currentPayoutItemSelectedIndex'];
    delete newValidation['currentPayoutItemSelectedIndex'];

    if (newInformation[ADD_TRANSACTION_PAYOUT_METHOD_NAME] !== METHOD_HOME) {
      delete newInformation[ADD_TRANSACTION_METHOD_HOME_NAME];
      delete newValidation[ADD_TRANSACTION_METHOD_HOME_NAME];
    }

    if (newInformation[ADD_TRANSACTION_PAYOUT_METHOD_NAME] !== METHOD_PICKUP) {
      delete newInformation[ADD_TRANSACTION_METHOD_PICKUP_NAME];
      delete newValidation[ADD_TRANSACTION_METHOD_PICKUP_NAME];
    }

    if (newInformation[ADD_TRANSACTION_PAYOUT_METHOD_NAME] !== METHOD_BANK) {
      delete newInformation[ADD_TRANSACTION_METHOD_BANK_NAME];
      delete newValidation[ADD_TRANSACTION_METHOD_BANK_NAME];
    }

    if (newInformation[ADD_TRANSACTION_PAYOUT_METHOD_NAME] !== METHOD_EWALLET) {
      delete newInformation[ADD_TRANSACTION_METHOD_EWALLET_NAME];
      delete newValidation[ADD_TRANSACTION_METHOD_EWALLET_NAME];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      if (isPreventNextStep1) return;

      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...addTransactionInformation,
          [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: {
            ...information,
            currentPayoutItemSelectedIndex,
          },
        },
      });

      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep + 1,
      });
    } else {
      newValidation = checkFormInputValidation(
        receiver?.fullname,
        ADD_TRANSACTION_RECEIVER_FIELD,
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
        ADD_TRANSACTION_PAYOUT_METHOD_FIELD,
        newValidation
      );

      if (
        information[ADD_TRANSACTION_PAYOUT_METHOD_NAME] &&
        currentPayoutItemSelectedIndex === -1
      ) {
        newValidation = checkMethodValidationVariant()?.[
          information[ADD_TRANSACTION_PAYOUT_METHOD_NAME]
        ](information, newValidation);
      }

      setValidation(newValidation);
    }
  };

  const renderActionsButton = () => {
    if (isMobile || isTablet)
      return (
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_next')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '100%',
              }}
              onClick={handleNextStep}
              isDisabled={isNextDisabled}
              isUseKeyDown={!isNextDisabled}
            />
          </NextWrap>
        </Fade>
      );

    return (
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_next')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            onClick={handleNextStep}
            isDisabled={isNextDisabled}
            isUseKeyDown={!isNextDisabled}
          />
          <ButtonCommon
            value={t('button_cancel')}
            onClick={handleOnToggleCancelPopup}
            styles={{
              margin: '0px',
              width: '50%',
              opacity: 0,
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            isDisabled={true}
          />
        </NextWrap>
      </Fade>
    );
  };

  return (
    <>
      {isOpenHolidayUrgentPopup && (
        <HolidayUrgentPopup
          isOpen={isOpenHolidayUrgentPopup}
          setOpen={setOpenHolidayUrgentPopup}
        />
      )}

      {isShowMissingInformationPopup && (
        <MissingInformationPopup
          isOpen={isShowMissingInformationPopup}
          onChange={handleOnChangeReceiver}
          onUpdate={handleOnUpdateReceiver}
        />
      )}

      {(isMobile || isTablet) && isOpenTableEstimatedPopup && (
        <TableEstimatedPopupMobile
          isOpen={isOpenTableEstimatedPopup}
          onClose={handleOnToggleTableEstimatedPopup}
        />
      )}

      {isDesktop && isOpenTableEstimatedPopup && (
        <TableEstimatedPopup
          isOpen={isOpenTableEstimatedPopup}
          onClose={handleOnToggleTableEstimatedPopup}
        />
      )}

      {isOpenDiscountImportantPopup &&
        renderDiscountImportantPopup({
          t,
          isOpen: isOpenDiscountImportantPopup,
          setOpen: setOpenDiscountImportantPopup,
          payoutMethod,
          onCloseExt: handleOnCloseDiscountImportantPopup,
          onClickExt: handleOnClickDiscountImportantPopup,
        })}

      <BodySectionHeader
        titleBodySection={
          isMakePayment
            ? t('add_transaction_title_make_payment')
            : t('add_transaction_title_new_transaction')
        }
        titleSection={t('add_transaction_title_select_receiver_payout_method')}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={isDisabled}
      />
      <ReceiverPayoutMethodStyled>
        <Title>
          {t('form_select_receiver')}
          <span>*</span>
        </Title>
        <CustomReceiversDropdown
          field={ADD_TRANSACTION_RECEIVER_FIELD}
          receivers={receivers}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          currentReceiverSelected={currentReceiverSelected}
          setCurrentReceiverSelected={setCurrentReceiverSelected}
          isDisabled={isDisabled}
        />
        {isDisplayReceiverDetailsSection && (
          <>
            <FieldsWrap>
              <ReceiverAvatar
                src={AVATAR_OF_RECEIVER_TYPE_REFERENCE[currentReceiver?.gender]}
                width={80}
                height={80}
              />
              <ReceiverDetails>
                <FieldWrap>
                  {receiver?.favorited && (
                    <FieldIcon>
                      <img
                        src={StarMarkedIcon}
                        width={20}
                        height={20}
                        alt=""
                        style={{ padding: '2px', marginRight: '8px' }}
                      />
                    </FieldIcon>
                  )}
                  <NameText>{receiver?.fullname}</NameText>
                </FieldWrap>
                {mobile?.visible && mobileNumber && (
                  <FieldWrap>
                    <FieldIcon>
                      <img
                        src={isHHMTTheme ? MobileHHMTIcon : MobileRemoxIcon}
                        width={20}
                        height={20}
                        alt=""
                      />
                    </FieldIcon>
                    <FieldValue>{mobileNumberMask || '-'}</FieldValue>
                  </FieldWrap>
                )}
                {phone?.visible && phoneNumber && (
                  <FieldWrap>
                    <FieldIcon>
                      <img
                        src={isHHMTTheme ? PhoneHHMTIcon : PhoneRemoxIcon}
                        width={20}
                        height={20}
                        alt=""
                      />
                    </FieldIcon>
                    <FieldValue>{phoneNumberMask || '-'}</FieldValue>
                  </FieldWrap>
                )}
                <FieldWrap>
                  <FieldIcon>
                    <img
                      src={isHHMTTheme ? LocationHHMTIcon : LocationRemoxIcon}
                      width={20}
                      height={20}
                      alt=""
                    />
                  </FieldIcon>
                  <FieldValue>{receiver?.fullAddress || '-'}</FieldValue>
                </FieldWrap>
              </ReceiverDetails>
            </FieldsWrap>
            <Divider />
            <Title
              ref={validRef}
              $isSelectRequired={validation[ADD_TRANSACTION_PAYOUT_METHOD_NAME]}
            >
              {t('form_choose_payout_method')}
            </Title>
            <PayoutMethodsGrid
              countryConfig={countryConfig}
              information={information}
              setInformation={setInformation}
              setValidation={setValidation}
              setInformationChangeBackup={setInformationChangeBackup}
              setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
              isDisabled={isDisabled}
              isSelectRequired={validation[ADD_TRANSACTION_PAYOUT_METHOD_NAME]}
            />
            {renderPayoutInformationSection()}
          </>
        )}
        {renderActionsButton()}
      </ReceiverPayoutMethodStyled>
    </>
  );
};

const ReceiverPayoutMethodStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: ${(props) =>
    props.$isSelectRequired ? 'var(--ds-c-red)' : 'var(--ds-c-blue-dark)'};
  margin-bottom: 8px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 8px;
  }
`;

const FieldsWrap = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 0px;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  width: ${(props) => (props.$isInline ? '207px' : '100%')};
  height: fit-content;
  min-height: 34px;
  float: ${(props) => props.$isFloatLeft && 'left'};
  margin-top: 0px;
  margin-right: ${(props) => props.$isFloatLeft && '32px'};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    min-height: fit-content;
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-grey-dark);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: #7a8189;
  }

  & span.payment-credit-card {
    text-transform: initial;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const NameText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #0e1012;

  padding: 0;
  margin: 0;
`;
const ReceiverAvatar = styled.img`
  width: 50px;
  height: 50px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 80px;
    height: 80px;
  }
`;
const ReceiverDetails = styled.div``;

const PayoutInformation = styled.div`
  margin-top: 16px;
`;
const PayoutDesc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: start;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;

  & img {
    margin-right: 4px;
  }

  &.bank {
    color: var(--ds-c-green-default);
    cursor: pointer;

    & img {
      filter: var(--ds-f-c-green);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    align-items: center;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
  width: 100%;
  margin-inline: -16px;
  padding-inline: 16px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: 44px;
    border-top: unset;
  }
`;

const Divider = styled.div`
  border-bottom: 1px dashed var(--ds-bg-2);
  margin-block: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-block: 20px;
  }
`;

export default ReceiverPayoutMethod;
