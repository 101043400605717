import {
  AlertModal,
  CancelFxAddTransactionModal,
  ChangePickupLocationTypeModal,
  CreateReceiverModal,
  FxCalculatorModalRateTimeoutModal,
  FxCalculatorModalTimeoutModal,
  PaymentInstructionModal,
  PickupLocationPreviewModal,
  PickupLocationViewDetailsModal,
  RemovePickupLocationByPayAmountChangeModal,
  SendMoneyReceiverModal,
  ServiceAlertsModal,
} from '../modals';
import FxPaymentInstructionModal from '../modals/FxPaymentInstructionModal';

const SERVICE_ALERTS_MODAL = 'SERVICE_ALERTS_MODAL';
const CREATE_RECEIVER_MODAL = 'CREATE_RECEIVER_MODAL';
const SEND_MONEY_RECEIVER_MODAL = 'SEND_MONEY_RECEIVER_MODAL';
const PAYMENT_INSTRUCTION_MODAL = 'PAYMENT_INSTRUCTION_MODAL';
const FX_PAYMENT_INSTRUCTION_MODAL = 'FX_PAYMENT_INSTRUCTION_MODAL';
const CHANGE_PICKUP_LOCATION_TYPE_MODAL = 'CHANGE_PICKUP_LOCATION_TYPE_MODAL';
const CANCEL_FX_ADD_TRANSACTION_MODAL = 'CANCEL_FX_ADD_TRANSACTION_MODAL';
const ALERT_MODAL = 'ALERT_MODAL';
const REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE =
  'REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE';
const FX_CALCULATOR_MODAL_TIMEOUT_MODAL = 'FX_CALCULATOR_MODAL_TIMEOUT_MODAL';
const FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL =
  'FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL';
const PICKUP_LOCATION_PREVIEW_MODAL = 'PICKUP_LOCATION_PREVIEW_MODAL';
const PICKUP_LOCATION_VIEW_DETAILS_MODAL = 'PICKUP_LOCATION_VIEW_DETAILS_MODAL';

export const PORTAL_NAME_MODAL_DEFINE = {
  [SERVICE_ALERTS_MODAL]: SERVICE_ALERTS_MODAL,
  [CREATE_RECEIVER_MODAL]: CREATE_RECEIVER_MODAL,
  [SEND_MONEY_RECEIVER_MODAL]: SEND_MONEY_RECEIVER_MODAL,
  [PAYMENT_INSTRUCTION_MODAL]: PAYMENT_INSTRUCTION_MODAL,
  [FX_PAYMENT_INSTRUCTION_MODAL]: FX_PAYMENT_INSTRUCTION_MODAL,
  [CHANGE_PICKUP_LOCATION_TYPE_MODAL]: CHANGE_PICKUP_LOCATION_TYPE_MODAL,
  [CANCEL_FX_ADD_TRANSACTION_MODAL]: CANCEL_FX_ADD_TRANSACTION_MODAL,
  [ALERT_MODAL]: ALERT_MODAL,
  [REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE]:
    REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE,
  [FX_CALCULATOR_MODAL_TIMEOUT_MODAL]: FX_CALCULATOR_MODAL_TIMEOUT_MODAL,
  [FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL]:
    FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL,
  [PICKUP_LOCATION_PREVIEW_MODAL]: PICKUP_LOCATION_PREVIEW_MODAL,
  [PICKUP_LOCATION_VIEW_DETAILS_MODAL]: PICKUP_LOCATION_VIEW_DETAILS_MODAL,
};

export const PORTAL_MODAL_REFERENCE = {
  [SERVICE_ALERTS_MODAL]: ServiceAlertsModal,
  [CREATE_RECEIVER_MODAL]: CreateReceiverModal,
  [SEND_MONEY_RECEIVER_MODAL]: SendMoneyReceiverModal,
  [PAYMENT_INSTRUCTION_MODAL]: PaymentInstructionModal,
  [FX_PAYMENT_INSTRUCTION_MODAL]: FxPaymentInstructionModal,
  [CHANGE_PICKUP_LOCATION_TYPE_MODAL]: ChangePickupLocationTypeModal,
  [CANCEL_FX_ADD_TRANSACTION_MODAL]: CancelFxAddTransactionModal,
  [ALERT_MODAL]: AlertModal,
  [REMOVE_PICKUP_LOCATION_BY_PAY_AMOUNT_CHANGE]:
    RemovePickupLocationByPayAmountChangeModal,
  [FX_CALCULATOR_MODAL_TIMEOUT_MODAL]: FxCalculatorModalTimeoutModal,
  [FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL]: FxCalculatorModalRateTimeoutModal,
  [PICKUP_LOCATION_PREVIEW_MODAL]: PickupLocationPreviewModal,
  [PICKUP_LOCATION_VIEW_DETAILS_MODAL]: PickupLocationViewDetailsModal,
};
