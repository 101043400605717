import DiscountTicket from 'components/Discount/components/DiscountTicket';
import {
  DESKTOP_MIN_WIDTH,
  PROMOTION,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import useLang from 'hooks/useLang';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import OffersEmpty from '../OffersEmpty';

const DiscountsTab = ({ items, type }) => {
  const { t } = useLang();
  const { pathname } = useLocation();

  const isCreateTxn = pathname === `${TRANSACTION_ADD_PREFIX}`;

  const offerEmptyTxt =
    type === PROMOTION
      ? t('label_offers_no_promotions')
      : t('label_offers_no_vouchers');

  const offerDesc =
    type === PROMOTION
      ? t('label_promotion_offer_desc')
      : t('label_voucher_offer_desc');

  const isPromotionEmpty = !items || items?.length === 0;
  if (isPromotionEmpty) {
    return <OffersEmpty offersEmptyTxt={offerEmptyTxt} offerDesc={offerDesc} />;
  }
  return (
    <DiscountsTabStyled $isCreateTxn={isCreateTxn}>
      <Desc>{offerDesc}</Desc>
      <DiscountsTabListStyled>
        {items?.map((item) => (
          <DiscountTicket key={item?.code} item={item} />
        ))}
      </DiscountsTabListStyled>
    </DiscountsTabStyled>
  );
};

const DiscountsTabStyled = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: ${(props) => (props.$isCreateTxn ? '0px' : '16px')};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &:hover {
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--ds-c-grey-hover);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
`;

const DiscountsTabListStyled = styled.div``;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-dark-gray);
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
`;

export default DiscountsTab;
