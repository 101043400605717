/* eslint-disable no-unused-vars */
import useStore from 'hooks/useStore';
import {
  SET_SHOW_LOADING,
  SET_SHOW_PENDING,
  SET_SHOW_SEARCHING,
} from 'store/action';

const useCommonStore = () => {
  const { state, setState } = useStore();
  const { isLoading, isSearching, currentUser, portalModal, configOccupation } =
    state;

  const { isShowPortalModal, namePortalModal } = portalModal || {};

  const setLoading = (isLoading = false) =>
    setState({
      action: SET_SHOW_LOADING,
      payload: isLoading,
    });

  const setPending = (isPending = false) =>
    setState({
      action: SET_SHOW_PENDING,
      payload: isPending,
    });

  const setSearching = (isSearching = false) =>
    setState({
      action: SET_SHOW_SEARCHING,
      payload: isSearching,
    });

  return {
    // STATE
    isLoading,
    isSearching,
    currentUser,
    portalModal,
    isShowPortalModal,
    namePortalModal,
    configOccupation,
    // DISPATCH
    setLoading,
    setSearching,
    setPending,
  };
};

export default useCommonStore;
