import axios from 'axios';
import { I18N_REDIRECTED } from 'constants';
import Cookies from 'helpers/cookies';
import newBaseApi from './base';
import newFefxApi from './fefx';

import { domUtils } from 'utils';
import {
  DESKTOP_MIN_WIDTH,
  EN_LANG,
  HEADER_AGENT_CODE_REFERENCE,
  HHMT_THEME,
  TABLET_MIN_WIDTH,
  THEME_REDIRECTED,
  THEME_REFERENCE,
} from '../constants';

const TIMEOUT_DEFAULT = 60000;

Cookies.setTheme(
  THEME_REDIRECTED,
  THEME_REFERENCE[process.env.REACT_APP_APP_NAME || HHMT_THEME]
);

const headers = {
  lang:
    Cookies.get(I18N_REDIRECTED) ||
    domUtils.getQueryWithName('lang') ||
    EN_LANG,
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'x-agent-code':
    HEADER_AGENT_CODE_REFERENCE[process.env.REACT_APP_APP_NAME || HHMT_THEME],
  'ea-platform':
    window.innerWidth >= DESKTOP_MIN_WIDTH
      ? 'web-desktop'
      : window.innerWidth < TABLET_MIN_WIDTH
      ? 'web-mobile'
      : 'web-tablet',
};

const initApi = () => {
  const base = newBaseApi(
    axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers,
      timeout: TIMEOUT_DEFAULT,
    })
  );

  const fefx = newFefxApi(
    axios.create({
      baseURL: process.env.REACT_APP_FEFX_URL,
      timeout: TIMEOUT_DEFAULT,
    })
  );

  return { ...base, ...fefx };
};

export const api = initApi();
