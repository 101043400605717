import {
  fetchAllDiscounts,
  getDiscountsTypeOther,
  getDiscountsTypePromotion,
} from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import { OFFERS_PREFIX, OFFERS_TABS, PROMOTION } from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { domUtils } from 'utils';
import DiscountsTab from '../../DiscountsTab';

const OffersMobile = () => {
  const { t } = useLang();
  const { token } = useAuth();

  const [currentSubTab, setCurrentSubTab] = useState(0);
  const [promotion, setPromotion] = useState(null);
  const [voucher, setVoucher] = useState(null);

  const isFetching = useRef();

  useEffect(() => {
    if (token && !voucher && !promotion && !isFetching.current) {
      isFetching.current = true;

      fetchAllDiscounts(token, (data) => {
        setPromotion(getDiscountsTypePromotion(data));

        setVoucher(getDiscountsTypeOther(data));

        isFetching.current = false;
      });
    }
  }, [token]);

  const getNumOfItem = (items) =>
    items?.length > 0 ? ' (' + items?.length + ')' : '';

  const tabContentReference = {
    0: <DiscountsTab items={promotion} type={PROMOTION} />,
    1: <DiscountsTab items={voucher} />,
  };

  const handleTabOnClick = (index) => {
    setCurrentSubTab(index);

    domUtils.changeAddressBarUrlWithoutReloadPage({
      prefix: OFFERS_PREFIX,
    });
  };

  return (
    <>
      <style>
        {`
          .offers-mobile-offcanvas {
            width: 100%!important;
            height: calc(100% - 70px);
            border: none!important;
          }
          .offers-mobile-offcanvas-backdrop {
            opacity: 0!important;
          }
        `}
      </style>
      <OffersMobileStyled>
        <TabWrap>
          {OFFERS_TABS.map((tab, index) => (
            <ButtonCommon
              key={tab.label}
              value={
                t(tab.label) +
                (index === 0 ? getNumOfItem(promotion) : getNumOfItem(voucher))
              }
              background={
                currentSubTab === index
                  ? 'var(--c-tab-active-2)'
                  : 'transparent'
              }
              color={'var(--ds-c-blue)'}
              styles={{
                width: '50%',
                borderRadius: '0px',
                height: '36px',
                float: 'left',
                color: 'var(--ds-c-blue)',
                ...(currentSubTab !== index && {
                  fontWeight: 500,
                  borderBottom: '2px solid #FFFFFF',
                }),
                ...(currentSubTab === index && {
                  borderBottom: '2px solid var(--ds-c-blue)',
                }),
                paddingInline: 0,
              }}
              onClick={() => handleTabOnClick(index)}
            />
          ))}
        </TabWrap>
        <ContentBody>{tabContentReference[currentSubTab]}</ContentBody>
      </OffersMobileStyled>
    </>
  );
};

const OffersMobileStyled = styled.div`
  position: relative;
`;

const TabWrap = styled.div`
  height: 44px;
  background-color: transparent;
  color: var(--ds-c-blue);
  padding: 4px;
`;

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 80px;
`;

export default OffersMobile;
