export const DISCOUNT_METHOD = {
  PERCENT: 'PERCENT',
  DOLLAR: 'DOLLAR',
  FLAT_FEE: 'FLAT FEE',
  DOLLAR_EXCLUDE_FEE: 'DOLLAR_EXCLUDE_FEE',
};

export const PROMOTION = 'PROMOTION';
export const VOUCHER = 'VOUCHER';

export const DISCOUNT_ACTIVE = 'ACTIVE';
export const DISCOUNT_COMING_SOON = 'COMING_SOON';
export const DISCOUNT_REDEEMED = 'REDEEMED';
export const DISCOUNT_EXPIRED = 'EXPIRED';
export const DISCOUNT_FULLY_REDEEMED = 'FULLY_REDEEMED';

export const DISCOUNT_TYPE_1 = 'label_offers_discount_method_3';
export const DISCOUNT_TYPE_2 = 'label_offers_discount_method_1';
export const DISCOUNT_TYPE_3 = 'label_offers_discount_method_2';

export const DISCOUNT_METHOD_DISPLAY_REFERENCE = {
  [DISCOUNT_METHOD.PERCENT]: DISCOUNT_TYPE_3,
  [DISCOUNT_METHOD.FLAT_FEE]: DISCOUNT_TYPE_1,
  [DISCOUNT_METHOD.DOLLAR]: DISCOUNT_TYPE_2,
  [DISCOUNT_METHOD.DOLLAR_EXCLUDE_FEE]: DISCOUNT_TYPE_2,
};
