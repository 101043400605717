/* eslint-disable no-unused-vars*/
import { RESET_PORTAL_MODAL_STORE } from 'constants';
import { SET_PORTAL_MODAL } from 'store/action';
import { functionUtils } from 'utils';
import useStore from './useStore';

const usePortalModal = () => {
  const { dispatch } = useStore();

  const showPortalModalName = ({
    name,
    title,
    content,
    action,
    actionClose,
  }) => {
    dispatch({
      type: SET_PORTAL_MODAL,
      payload: {
        isShowPortalModal: true,
        namePortalModal: name,
        ...(title && { title }),
        ...(content && { content }),
        ...(functionUtils.isFunction(action) && { action }),
        ...(functionUtils.isFunction(actionClose) && { actionClose }),
      },
    });
  };

  const closePortalModalName = (name) => {
    dispatch({
      type: SET_PORTAL_MODAL,
      payload: RESET_PORTAL_MODAL_STORE,
    });
  };

  return {
    showPortalModalName,
    closePortalModalName,
  };
};

export default usePortalModal;
