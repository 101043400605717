/* eslint-disable no-unused-vars */
import { api } from 'api';
import { LogUtil } from 'components/common/LogUtil';
import {
  createOrder,
  createOrderAsGuest,
  updateOrder,
  updateOrderAsGuest,
} from 'components/fx/func';
import {
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_PAYID,
  STATUS_FAIL,
  STATUS_SUCCESS,
} from 'constants';
import { AS_GUEST } from 'constants/fx';
import useAuth from 'hooks/useAuth';
import useCommonStore from 'hooks/useCommonStore';
import { isEmpty } from 'lodash';
import useFxAddTransaction from './useFxAddTransaction';
import useFxFormAddTransactionStore from './useFxFormAddTransactionStore';

const useFxPayment = () => {
  const { token } = useAuth();

  const { setLoading, setPending } = useCommonStore();
  const {
    paymentMethodName,
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    isDiscountApplied,
    setTokenTransaction,
    getPayloadToCreateOrderSubmit,
    confirmInformationEmail,
    isAdditionalInformationRequired,
    transactionDetails,
  } = useFxFormAddTransactionStore();
  const {
    isDisabled,
    isUpdateTran,
    isCreateNewTran,
    orderRef,
    isFxAsGuest,
    getFxTransactionInformation,
  } = useFxAddTransaction();

  const handlePayProcess = async ({
    transactionInformation,
    setTransactionInformation,
    setShowFormPaymentByDeposit,
    setShowTransactionDetails,
  }) => {
    try {
      setTransactionInformation(transactionInformation);
      if (paymentMethodName === METHOD_EPAYMENT) {
        setShowFormPaymentByDeposit(true);
      }
      if (
        paymentMethodName === METHOD_PAYID ||
        paymentMethodName === METHOD_DEPOSIT
      ) {
        setAddTransactionCurrentStep(currentStep + 1);
      }
      setShowTransactionDetails(false);
      setLoading(false);
    } catch (error) {
      LogUtil.error('handlePayProcess', 'error', error);
    }
  };

  const handleOnPay = async ({
    createTransactionPayload,
    transactionInformation,
    setTransactionInformation,
    setShowTransactionDetails,
    setShowFormPaymentByDeposit,
    setErrorCode,
    setShowDiscountInvalidPopup,
  }) => {
    setLoading(true);

    const currentOrderRef = transactionInformation?.orderRef || orderRef;

    if (isUpdateTran) {
      LogUtil.log('isUpdateTran', 'start');
      const isChangePaymentMethod =
        (!isEmpty(transactionInformation)
          ? transactionInformation?.paymentMethod
          : transactionDetails?.paymentMethod) &&
        paymentMethodName &&
        (!isEmpty(transactionInformation)
          ? transactionInformation?.paymentMethod !== paymentMethodName
          : transactionDetails?.paymentMethod !== paymentMethodName);

      const isUpdateTranWithoutSubmit =
        isDisabled &&
        !isChangePaymentMethod &&
        (paymentMethodName === METHOD_PAYID ||
          paymentMethodName === METHOD_DEPOSIT);
      if (isUpdateTranWithoutSubmit) {
        LogUtil.log('isUpdateTranWithoutSubmit', 'start');
        setAddTransactionCurrentStep(currentStep + 1);
        setShowTransactionDetails(false);
        setLoading(false);
        return;
      }
      const isUpdateTranWithoutSubmitByCC =
        isDisabled &&
        !isChangePaymentMethod &&
        paymentMethodName === METHOD_EPAYMENT;
      if (isUpdateTranWithoutSubmitByCC) {
        LogUtil.log('isUpdateTranWithoutSubmitByCC', 'start');
        try {
          const transactionInformation = await getFxTransactionInformation({
            token,
            orderRef: currentOrderRef,
            email: confirmInformationEmail,
          });
          if (!isEmpty(transactionInformation)) {
            handlePayProcess({
              transactionInformation,
              setTransactionInformation,
              setShowFormPaymentByDeposit,
              setShowTransactionDetails,
            });
          }
        } catch (error) {
          console.error(error?.message);
          setLoading(false);
        }
        return;
      }

      try {
        const updateTransactionPayload = {
          ...createTransactionPayload,
          orderRef: currentOrderRef,
          ...(!isEmpty(transactionInformation?.customer) && {
            customer: transactionInformation?.customer,
          }),
        };
        console.log('updateTransactionPayload:::', updateTransactionPayload);

        const response = !isFxAsGuest
          ? await updateOrder(token, updateTransactionPayload)
          : await updateOrderAsGuest(updateTransactionPayload);
        const data = !isEmpty(response?.data) ? response?.data : response;
        if (!isEmpty(data)) {
          const transactionInformation = await getFxTransactionInformation({
            token,
            orderRef: data?.orderRef,
            email: confirmInformationEmail,
          });
          handlePayProcess({
            transactionInformation,
            setTransactionInformation,
            setShowFormPaymentByDeposit,
            setShowTransactionDetails,
          });
        }
      } catch (error) {
        console.error(error?.message);
        if (isDiscountApplied) {
          setErrorCode(error?.response?.data?.errorCode);
          setShowDiscountInvalidPopup(true);
        }
      }
      return;
    }
    if (isCreateNewTran) {
      LogUtil.log('isCreateNewTran', 'start');
      try {
        const response = !isFxAsGuest
          ? await createOrder(token, createTransactionPayload)
          : await createOrderAsGuest(createTransactionPayload);
        const data = !isEmpty(response?.data) ? response?.data : response;
        if (!isEmpty(data)) {
          const transactionInformation = await getFxTransactionInformation({
            token,
            orderRef: data?.orderRef || currentOrderRef,
            email: confirmInformationEmail,
          });
          if (!isEmpty(transactionInformation)) {
            // handle save profile occupation
            if (!isFxAsGuest && isAdditionalInformationRequired) {
              const payloadOccupation = {
                job: transactionInformation.occupation,
                previousJob: transactionInformation.previousOccupation,
              };

              const { data: dataOccupation } = await api.getOccupation(token);
              if (!isEmpty(dataOccupation)) {
                const profileJob = dataOccupation?.job;
                const profilePrevJob = dataOccupation?.previousJob;

                const isChangeOccupation =
                  profileJob !== payloadOccupation?.job;
                const isChangePrevOccupation =
                  profilePrevJob &&
                  payloadOccupation?.previousJob &&
                  profilePrevJob !== payloadOccupation?.previousJob;

                if (
                  transactionInformation.occupation &&
                  (isChangeOccupation || isChangePrevOccupation)
                ) {
                  await api.saveOccupation(token, payloadOccupation);
                }
              }
            }

            handlePayProcess({
              transactionInformation,
              setTransactionInformation,
              setShowFormPaymentByDeposit,
              setShowTransactionDetails,
            });
          }
        }
      } catch (error) {
        console.error(error?.message);
        setLoading(false);
        if (isDiscountApplied) {
          setErrorCode(error?.response?.data?.errorCode);
          setShowDiscountInvalidPopup(true);
        }
      }
      return;
    }

    try {
      const transactionInformation = await getFxTransactionInformation({
        token,
        orderRef: currentOrderRef,
        email: confirmInformationEmail,
      });
      if (!isEmpty(transactionInformation)) {
        handlePayProcess({
          transactionInformation,
          setTransactionInformation,
          setShowFormPaymentByDeposit,
          setShowTransactionDetails,
        });
      }
    } catch (error) {
      console.error(error?.message);
      setLoading(false);
    }
  };
  const handleOnPayProcess = ({
    transactionInformation,
    setTransactionInformation,
    setShowTransactionDetails,
    setShowFormPaymentByDeposit,
    setErrorCode,
    setShowDiscountInvalidPopup,
  }) => {
    const createTransactionPayload = getPayloadToCreateOrderSubmit();
    console.log('createTransactionPayload:::', createTransactionPayload);
    console.log('transactionInformation:::', transactionInformation);

    handleOnPay({
      createTransactionPayload,
      transactionInformation,
      setTransactionInformation,
      setShowTransactionDetails,
      setShowFormPaymentByDeposit,
      setErrorCode,
      setShowDiscountInvalidPopup,
    });
  };

  const handleOnPayByDeposit = async ({
    urlRedirect,
    transactionInformation,
    handleOnCloseFormPaymentByDeposit,
  }) => {
    const currentOrderRef = transactionInformation?.orderRef || orderRef;
    const email = transactionInformation?.customer?.email;
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    const failPath = isFxAsGuest
      ? `${homeUrl}/fx-tran?as=${AS_GUEST}&&orderRef=${currentOrderRef}&&paymentStatus=${STATUS_FAIL}&&email=${email}`
      : `${homeUrl}/fx-tran?orderRef=${currentOrderRef}&&paymentStatus=${STATUS_FAIL}`;

    if (urlRedirect) {
      window.location.href = urlRedirect;
      return;
    }
    window.location.href = failPath;
    handleOnCloseFormPaymentByDeposit();
  };
  const handleOnPayByDepositProcess = (
    urlRedirect,
    transactionInformation,
    handleOnCloseFormPaymentByDeposit
  ) => {
    handleOnPayByDeposit({
      urlRedirect,
      transactionInformation,
      handleOnCloseFormPaymentByDeposit,
    });
  };

  const paymentMintProcess = async (orderRef, tokenTransaction, email) => {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    const successPath = isFxAsGuest
      ? `${homeUrl}/fx-tran?as=${AS_GUEST}&&orderRef=${orderRef}&&paymentStatus=${STATUS_SUCCESS}&&email=${email}`
      : `${homeUrl}/fx-tran?orderRef=${orderRef}&&paymentStatus=${STATUS_SUCCESS}`;
    const failPath = isFxAsGuest
      ? `${homeUrl}/fx-tran?as=${AS_GUEST}&&orderRef=${orderRef}&&paymentStatus=${STATUS_FAIL}&&email=${email}`
      : `${homeUrl}/fx-tran?orderRef=${orderRef}&&paymentStatus=${STATUS_FAIL}`;

    try {
      const { data } = await api.fxProcessMint(orderRef, tokenTransaction);
      if (data) {
        const { success } = data;

        setTokenTransaction();
        setPending(false);

        if (success) {
          window.location.href = successPath;
        } else {
          window.location.href = failPath;
        }
      }
    } catch (error) {
      LogUtil.error('paymentMintProcess', 'error', error);
      setTokenTransaction();
      setPending(false);
      window.location.href = failPath;
    }
  };

  return {
    handleOnPayProcess,
    handleOnPayByDepositProcess,
    paymentMintProcess,
  };
};

export default useFxPayment;
