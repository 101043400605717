import NotifyIcon1 from 'assets/icons/notifies/1.svg';
import NotifyIcon2 from 'assets/icons/notifies/2.svg';
import NotifyIcon3 from 'assets/icons/notifies/3.svg';
import NotifyIcon4 from 'assets/icons/notifies/4.svg';
import NotifyIcon5 from 'assets/icons/notifies/5.svg';

export const NOTIFY_ICON_REFERENCE = {
  payment_reminder: NotifyIcon1,
  cancelled: NotifyIcon4,
  claimed: NotifyIcon3,
  pickup_code: NotifyIcon2,
  payment_received: NotifyIcon2,
  on_hold: NotifyIcon5,
  delivered: NotifyIcon3,
};

export const NUMBER_OF_DAYS_EXIST = 15;

export const NOTIFY_TYPE_TRANSACTION = 'transaction';
export const NOTIFY_TYPE_FX_TRANSACTION = 'fx_transaction';
