import PickupByAPIcon from 'assets/ver2/icons/fx-add-transaction/pickup-by-australia-post-icon.svg';
import PickupByEAIcon from 'assets/ver2/icons/fx-add-transaction/pickup-by-ea-icon.svg';
import {
  PAYMENT_CREADIT_CARD_NO,
  PAYMENT_CREADIT_CARD_TYPE,
} from 'constants/form-add-transaction';
import {
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_PAYID,
} from 'constants/transaction';
import {
  addressValid,
  emailValid,
  fieldValid,
  mobilePhoneValid,
  msgValid,
  textValid,
} from 'helpers';

export const PAY_AMOUNT_MIN_LIMIT = 100;
export const PAY_AMOUNT_MAX_LIMIT = 30000;

export const AP_PAY_AMOUNT_MIN_LIMIT = 250;
export const AP_PAY_AMOUNT_MAX_LIMIT = 5000;

export const PAY_AMOUNT_INPUT_MAX = 1000000000;

export const PAY_BY_CC_AMOUNT_MIN_LIMIT = 0;
export const PAY_BY_CC_AMOUNT_MAX_LIMIT = 2000;

export const AS_GUEST = 'guest';
export const AS_REGISTER = 'register';

export const ORDER_TABLE_HEADING = {
  currencyName: 'currencyName',
  buyAmount: 'buyAmount',
  payAmount: 'payAmount',
  rate: 'rate',
  action: '',
};
export const ORDER_TABLE_HEADING_TRANSLATE = {
  currencyName: 'label_currency_name',
  buyAmount: 'label_buy_amount',
  payAmount: 'label_pay_amount',
  rate: 'label_buy_rate_per_aud',
  action: '',
};

export const FX_TRANSACTION_TABLE_HEADING = {
  Reference: 'orderRef',
  Currencies: 'currencies',
  Status: 'orderStatus',
  'Purchase Date': 'createdDate',
  'Total to pay': 'orderTotal',
};

export const FX_TRANSACTION_TABLE_HEADING_TRANSLATE = {
  Reference: 'label_ref_number',
  Currencies: 'label_purchased _currency',
  Status: 'label_status',
  'Purchase Date': 'label_purchase_date',
  'Total to pay': 'label_total_to_pay_aud',
};

export const FX_TRANSACTION_TABLE_HEADING_UNABLE_SORT = [
  'Reference',
  'Status',
  'Currencies',
];

export const FX_TRANSACTION_TABLE_SORT_ORDER_INITIAL = {
  Reference: null,
  Currencies: null,
  Status: null,
  'Purchase Date': null,
  'Total to pay': null,
};

export const PICKUP_LOCATION_TYPE_1 = 'pickupByEA';
export const PICKUP_LOCATION_TYPE_2 = 'pickupByAustraliaPost';
export const PICKUP_LOCATION_TYPE_REFERENCE = {
  [PICKUP_LOCATION_TYPE_1]: {
    type: PICKUP_LOCATION_TYPE_1,
    labelKey: 'fx_add_transaction_step2_by_ea_label',
    iconSrc: PickupByEAIcon,
    serviceFeeAmount: 0,
    collect: {
      min: 100,
      max: 30000,
    },
  },
  [PICKUP_LOCATION_TYPE_2]: {
    type: PICKUP_LOCATION_TYPE_2,
    labelKey: 'fx_add_transaction_step2_by_australia_post_label',
    iconSrc: PickupByAPIcon,
    serviceFeeAmount: 10,
    collect: {
      min: 250,
      max: 5000,
    },
  },
};

export const PICKUP_LOCATION_STATES = [
  { value: 'NSW', text: 'New South Wales' },
  { value: 'VIC', text: 'Victoria' },
  { value: 'WA', text: 'Western Australia' },
  { value: 'QLD', text: 'Queensland' },
  { value: 'NT', text: 'Northern Territory' },
  { value: 'SA', text: 'South Australia' },
];

export const PICKUP_LOCATION_NAME_REFERENCE = {
  HHMT: `Hai Ha Money Transfer - {{suburb}}`,
  REMOX: `Remox - {{suburb}}`,
  FEFX: 'First Eastern fx - {{suburb}}',
};

export const FX_ADD_TRANSACTION_PAYMENT_METHOD_INSTRUCTION = {
  [METHOD_PAYID]: `fx_add_transaction_pm_by_payid_instruction`,
  [METHOD_DEPOSIT]: `fx_add_transaction_pm_by_bank_instruction`,
  [METHOD_EPAYMENT]: `fx_add_transaction_pm_by_epayment_instruction`,
};

export const FX_CALCULATOR_FORM_PAY_NAME = 'fx-calculator-form-pay-name';
export const FX_CALCULATOR_FORM_BUY_NAME = 'fx-calculator-form-buy-name';
export const FX_CALCULATOR_FORM_AMOUNT_FIELDS = [
  {
    label: 'fx_buy_label',
    name: FX_CALCULATOR_FORM_BUY_NAME,
    type: 'text',
    placeholder: '0',
    isRequired: false,
    isReadOnly: false,
    msgRequired: 'form_required',
  },
  {
    label: 'fx_pay_label',
    name: FX_CALCULATOR_FORM_PAY_NAME,
    type: 'text',
    placeholder: '0',
    isRequired: false,
    isReadOnly: false,
    msgRequired: 'form_required',
  },
];

export const FX_ADD_TRANSACTION_MESSAGE_NAME =
  'fx-add-transaction-message-field';
export const FX_ADD_TRANSACTION_MESSAGE_FIELD = {
  label: 'fx_add_transaction_order_note_label',
  name: FX_ADD_TRANSACTION_MESSAGE_NAME,
  type: 'text',
  placeholder: 'fx_add_transaction_order_note_example',
  isRequired: false,
  isReadOnly: false,
  msgInvalid: 'form_message_invalid_1',
  checkValidFn: msgValid,
};

export const FX_ADD_TRANSACTION_ADDRESS_FINDER_NAME =
  'fx-add-transaction-address-field';
export const FX_ADD_TRANSACTION_ADDRESS_FINDER_FIELD = {
  label: '',
  name: FX_ADD_TRANSACTION_ADDRESS_FINDER_NAME,
  type: 'text',
  placeholder: 'form_search_location_placeholder',
  isRequired: false,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE =
  'fx-add-transaction-pickup-location-type';
export const FX_ADD_TRANSACTION_PICKUP_LOCATION =
  'fx-add-transaction-pickup-location';

export const FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME =
  'fx-add-transaction-payment-method-field';
export const FX_ADD_TRANSACTION_PAYMENT_METHOD_FIELD = {
  label: '',
  name: FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  type: '',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_choose_payment_method_invalid',
};

export const FX_ADD_TRANSACTION_PICKUP_DATE = 'fx-add-transaction-pickup-date';
export const FX_ADD_TRANSACTION_PICKUP_DATE_DATA =
  'fx-add-transaction-pickup-date-data';

/*--------------- ENTER YOUR INFORMATION ---------------*/
// Personal information
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME =
  'fx-add-transaction-enter-information-first-name-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME =
  'fx-add-transaction-enter-information-middle-name-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME =
  'fx-add-transaction-enter-information-last-name-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_GENERAL_DETAILS_NAME_FIELDS =
  [
    {
      label: 'form_given_name',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME,
      type: 'text',
      placeholder: 'form_placeholder',
      tooltip: 'form_name_tooltip_3',
      isRequired: true,
      isReadOnly: false,
      msgInvalid: 'form_name_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: textValid,
    },

    {
      label: 'form_fx_middle_name',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME,
      type: 'text',
      tooltip: 'form_name_tooltip_2',
      isRequired: false,
      isReadOnly: false,
      msgInvalid: 'form_name_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: textValid,
    },
    {
      label: 'form_family_name',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME,
      type: 'text',
      placeholder: 'form_placeholder',
      tooltip: 'form_name_tooltip_2',
      isRequired: true,
      isReadOnly: false,
      msgInvalid: 'form_name_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: textValid,
    },
  ];

export const FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME =
  'fx-add-transaction-enter-information-dob-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_DOB_FIELD = {
  label: 'form_date_of_birth',
  name: FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME,
  type: 'text',
  placeholder: 'DD/MM/YYYY',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_date_of_birth_invalid_1',
  msgInvalid2: 'form_date_of_birth_invalid_2',
  msgRequired: 'form_required',
  checkValidFn: fieldValid,
};

export const FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME =
  'fx-add-transaction-enter-information-gender-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD =
  {
    label: 'form_gender',
    name: FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME,
    type: 'radio',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  };
// Contact
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME =
  'fx-add-transaction-enter-information-mobile-name';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_FIELD = {
  label: 'form_mobile',
  name: FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME,
  type: 'tel',
  placeholder: 'form_mobile_placeholder_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_mobile_invalid_2',
  msgStartWith: 'form_mobile_invalid_3',
  msgRequired: 'form_required',
  checkValidFn: mobilePhoneValid,
};

export const FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME =
  'fx-add-transaction-enter-information-email-name';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD = {
  label: 'form_email',
  name: FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME,
  type: 'email',
  placeholder: 'form_email_placeholder_2',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_email_invalid',
  msgExisted: 'form_email_existed',
  msgRequired: 'form_required',
  checkValidFn: emailValid,
};
// Residential address
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SWITCH =
  'fx-add-transaction-enter-information-address-switch-field';
// // Lookup up address
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME =
  'fx-add-transaction-enter-information-address-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD =
  {
    label: 'form_address_finder',
    name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME,
    type: 'text',
    placeholder: 'form_placeholder_type_to_search',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  };
// // Enter manually
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME =
  'fx-add-transaction-enter-information-address-unit-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME =
  'fx-add-transaction-enter-information-address-street-no-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME =
  'fx-add-transaction-enter-information-address-street-name-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME =
  'fx-add-transaction-enter-information-address-street-type-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME =
  'fx-add-transaction-enter-information-address-post-code-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME =
  'fx-add-transaction-enter-information-address-suburb-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME =
  'fx-add-transaction-enter-information-address-state-field';
export const FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS =
  [
    {
      label: 'form_address_unit',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME,
      type: 'text',
      placeholder: 'form_placeholder',
      isRequired: false,
      isReadOnly: false,
      msgInvalid: 'form_address_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: addressValid,
    },
    {
      label: 'form_address_street_no',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME,
      type: 'text',
      placeholder: 'form_placeholder',
      isRequired: true,
      isReadOnly: false,
      msgInvalid: 'form_address_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: addressValid,
    },
    {
      label: 'form_address_street_name',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME,
      type: 'text',
      placeholder: 'form_placeholder',
      isRequired: true,
      isReadOnly: false,
      msgInvalid: 'form_address_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: addressValid,
    },
    {
      label: 'form_address_street_type',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME,
      type: 'text',
      placeholder: 'form_placeholder_please_select',
      isRequired: true,
      isReadOnly: false,
      msgRequired: 'form_required',
      checkValidFn: textValid,
    },
    {
      label: 'form_address_post_code',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME,
      type: 'number',
      placeholder: 'form_placeholder_type_to_search',
      isRequired: true,
      isReadOnly: true,
      msgInvalid: 'form_name_invalid_1',
      msgRequired: 'form_required',
      checkValidFn: addressValid,
    },
    {
      label: 'form_address_suburb',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
      type: 'text',
      placeholder: '-',
      isRequired: true,
      isReadOnly: true,
      msgRequired: 'form_required',
      checkValidFn: textValid,
    },
    {
      label: 'form_address_state',
      name: FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
      type: 'text',
      placeholder: '-',
      isRequired: true,
      isReadOnly: true,
      msgRequired: 'form_required',
      checkValidFn: textValid,
    },
  ];
// Additional information
export const FX_ADD_TRANSACTION_OCCUPATION_NAME =
  'fx-add-transaction-occupation-field';
export const FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME =
  'fx-add-transaction-previous-employed-field';
export const FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS = [
  {
    label: 'form_your_occupation',
    name: FX_ADD_TRANSACTION_OCCUPATION_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_previous_employed',
    name: FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
];
export const FX_ADD_TRANSACTION_PURPOSE_NAME =
  'fx-add-transaction-purpose-field';
export const FX_ADD_TRANSACTION_PURPOSE_FIELD = {
  label: 'form_purpose_transaction',
  name: FX_ADD_TRANSACTION_PURPOSE_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};
export const FX_ADD_TRANSACTION_SOURCE_NAME = 'fx-add-transaction-source-field';
export const FX_ADD_TRANSACTION_SOURCE_FIELD = {
  label: 'form_source_of_funds',
  name: FX_ADD_TRANSACTION_SOURCE_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
};
// Upload your ID
export const FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_SWITCH =
  'fx-add-transaction-upload-id-driver-licence-switch-field';
// // DL
export const FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME =
  'fx-add-transaction-upload-id-driver-licence-front-field';
export const FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS =
  [
    {
      label: 'form_driver_licence_front',
      name: FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME,
      type: 'file',
      isRequired: true,
      isReadOnly: false,
      msgSizeLimit: 'form_document_upload_invalid_1',
      msgTypeAccept: 'form_document_upload_invalid_2',
      msgRequired: 'form_document_upload_invalid_3',
    },
  ];
// // P
export const FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME =
  'fx-add-transaction-upload-id-passport-field';
export const FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_PASSPORT_FIELD = {
  label: 'form_passport',
  name: FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME,
  type: 'file',
  isRequired: true,
  isReadOnly: false,
  msgSizeLimit: 'form_document_upload_invalid_1',
  msgTypeAccept: 'form_document_upload_invalid_2',
  msgRequired: 'form_document_upload_invalid_3',
  ref: 'refPassport',
};

export const RESET_FX_ADD_TRANSACTION_ORDER = {
  currencyOrders: [],
  [FX_ADD_TRANSACTION_MESSAGE_NAME]: '',
};
export const RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION = {
  [FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE]: '',
  [FX_ADD_TRANSACTION_PICKUP_LOCATION]: null,
};
export const RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD = {
  [FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]: '',
  [PAYMENT_CREADIT_CARD_NO]: '',
  [PAYMENT_CREADIT_CARD_TYPE]: '',
  discountsAvailable: [],
  discountsInactive: [],
  discountApplied: null,
};
export const RESET_FX_ADD_TRANSACTION_PICKUP_DATE = {
  [FX_ADD_TRANSACTION_PICKUP_DATE]: '',
  [FX_ADD_TRANSACTION_PICKUP_DATE_DATA]: null,
};
export const RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION = {
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME]: '',
  isEnterAddressManuallySelected: false,
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: '',
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: '',
  [FX_ADD_TRANSACTION_OCCUPATION_NAME]: '',
  [FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: '',
  [FX_ADD_TRANSACTION_PURPOSE_NAME]: '',
  [FX_ADD_TRANSACTION_SOURCE_NAME]: '',
};
export const RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD = {
  isDriverLicenceSelected: true,
  [FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME]: null,
  [FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME]: null,
};
export const RESET_FX_ADD_TRANSACTION_INFORMATION_STORE = {
  order: RESET_FX_ADD_TRANSACTION_ORDER,
  pickupLocation: RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION,
  paymentMethod: RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD,
  pickupDate: RESET_FX_ADD_TRANSACTION_PICKUP_DATE,
  confirmInformation: RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION,
  documentUpload: RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD,
};

/* BEGIN - fx form contact details */
export const FX_CONTACT_DETAIL_EMAIL_NAME = 'fx-contact-detail-email-name';
export const FX_CONTACT_DETAIL_EMAIL_FIELD = {
  label: 'form_email_2',
  name: FX_CONTACT_DETAIL_EMAIL_NAME,
  type: 'email',
  placeholder: 'form_email_placeholder_2',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_email_invalid',
  msgExisted: 'form_email_existed',
  msgRequired: 'form_required',
  checkValidFn: emailValid,
};
export const FX_CONTACT_DETAIL_MOBILE_NAME = 'fx-contact-detail-mobile-name';
export const FX_CONTACT_DETAIL_MOBILE_FIELD = {
  label: 'form_mobile_2',
  name: FX_CONTACT_DETAIL_MOBILE_NAME,
  type: 'tel',
  placeholder: 'form_mobile_placeholder_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_mobile_invalid_2',
  msgStartWith: 'form_mobile_invalid_3',
  msgRequired: 'form_required',
  checkValidFn: mobilePhoneValid,
};
export const RESET_FORM_FX_CONTACT_DETAILS = {
  [FX_CONTACT_DETAIL_EMAIL_NAME]: '',
  [FX_CONTACT_DETAIL_MOBILE_NAME]: '',
};
/* END - fx form contact details */
